import {
  CalendarDateProperty,
  CollectionProperty,
  DateTimeProperty,
  NumberProperty,
  ObjectProperty,
  PercentageProperty,
  TextProperty
} from '../models/properties.js'

import { ApplicationModel } from '../applications/application-model.js'
import { DEVICE_TYPE } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { ProcessingStatusMixin } from '../../components/mixins/processing-status-mixin.js'

export class MonitoringModel extends ProcessingStatusMixin(Model) {
  constructor(m = {}) {
    super(m)
    this.application = new ObjectProperty({
      label: Localization.tr('Species'),
      value: m.application,
      type: ApplicationModel,
      formatValue: value => value.name.formattedValue
    })

    this.applicationUnit = new TextProperty({
      label: Localization.tr('Unit'),
      value: this.application.value ? this.application.value.unit.value : null,
      formatValue: () =>
        this.application.value ? this.application.value.unit.formattedValue : Localization.tr('animals')
    })

    this.applicationLatinName = new TextProperty({
      label: Localization.tr('Species'),
      value: this.application.value ? this.application.value.latinName.value : null
    })

    this.weightGroups = new CollectionProperty({
      label: Localization.tr('Groups'),
      value: m.weightGroups
    })

    this.dateTime = new DateTimeProperty({
      label: Localization.tr('Date & time'),
      value: m.dateTime
    })

    this.deviceSerialNumber = new TextProperty({
      label: Localization.tr('Device'),
      value: m.deviceSerialNumber
    })

    this.deviceType = new TextProperty({
      label: Localization.tr('Device Type'),
      value: m.deviceType,
      formatValue: value => {
        switch (m.deviceType) {
          case DEVICE_TYPE.MOBILE:
            return Localization.tr('Mobile')
          case DEVICE_TYPE.XPERCOUNT:
            return Localization.tr('XperCount')
          default:
            return Localization.tr('Manual')
        }
      }
    })

    this.populationName = new TextProperty({
      label: Localization.tr('Population Name'),
      value: m.populationName
    })

    this.populationId = new TextProperty({
      label: Localization.tr('Population ID'),
      value: m.populationId
    })

    this.populationDateOfHatch = new CalendarDateProperty({
      label: Localization.tr('Date of hatch'),
      value: m.populationDateOfHatch
    })

    this.populationDaysPostHatch = new NumberProperty({
      label: Localization.tr('Days post-hatch'),
      value: m.populationDaysPostHatch,
      unit: Localization.tr('days')
    })

    this.populationStage = new TextProperty({
      label: Localization.tr('Stage'),
      value: m.populationStage
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: m.comment
    })

    this.animalAmountCounted = new NumberProperty({
      label: Localization.tr('Total count'),
      value: m.animalAmountCounted,
      unit: this.applicationUnit.formattedValue,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.averageLength = new NumberProperty({
      label: Localization.tr('Avg length'),
      value: m.averageLength,
      unit: Localization.tr('mm'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.coefficientVariation = new PercentageProperty({
      label: Localization.tr('Length CV'),
      value: m.coefficientVariation,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.weightCoefficientVariation = new PercentageProperty({
      label: Localization.tr('Weight CV'),
      value: m.weightCoefficientVariation,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.lengthDistribution = new CollectionProperty({
      label: Localization.tr('Length distribution'),
      value: m.lengthDistribution
    })

    this.lengthHistogram = new ObjectProperty({
      label: Localization.tr('Length distribution'),
      value: m.lengthHistogram
    })

    this.averageWeight = new NumberProperty({
      label: Localization.tr('Avg weight'),
      value: m.averageWeight,
      unit: Localization.tr('g'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.totalWeight = new NumberProperty({
      label: Localization.tr('Total weight'),
      value: m.totalWeight,
      unit: Localization.tr('g'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.weightDistribution = new CollectionProperty({
      label: Localization.tr('Weight distribution'),
      value: m.weightDistribution
    })

    this.weightHistogram = new ObjectProperty({
      label: Localization.tr('Weight distribution'),
      value: m.weightHistogram
    })

    this.sessions = new CollectionProperty({
      label: Localization.tr('Sessions'),
      value: m.sessions
    })

    this.sessionPictures = new CollectionProperty({
      label: Localization.tr('Pictures'),
      value: m.sessionPictures
    })
  }

  export() {
    return {
      source: this.deviceType.formattedValue,
      date: this.dateTime.formattedValue,
      populationId: this.populationName.formattedValue,
      totalCount: this.animalAmountCounted.formattedValue,
      averageLength: this.averageLength.formattedValue,
      averageWeight: this.averageWeight.formattedValue,
      coefficientVariation: this.coefficientVariation.formattedValue,
      weightCoefficientVariation: this.weightCoefficientVariation.formattedValue,
      dateOfHatch: this.populationDateOfHatch.formattedValue,
      daysPostHatch: this.populationDaysPostHatch.formattedValue,
      stage: this.populationStage.formattedValue,
      specie: this.application.formattedValue,
      device: this.deviceSerialNumber.formattedValue,
      comment: this.comment.formattedValue,
      weightDistribution: this.weightDistribution.value,
      lengthDistribution: this.lengthDistribution.value
    }
  }
}
