import { Analytics } from '../../libs/analytics.js'
import { SeaElementMixin } from '../element/sea-element.js'

import { dedupingMixin } from '../../../node_modules/@polymer/polymer/lib/utils/mixin.js'

export const AnalyticsMixin = dedupingMixin(
  Base =>
    class AnalyticsMixinClass extends SeaElementMixin(Base) {
      static get properties() {
        return {
          analyticsAction: {
            type: String
          },
          analyticsSource: {
            type: String
          }
        }
      }

      _sendEvent(type) {
        if (this.analyticsAction) {
          Analytics.track(type, {
            action: this.analyticsAction,
            source: this.analyticsSource
          })
        }
      }
    }
)
