import { DateTimeProperty, TextProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export const CUSTOMER_TYPES = {
  BASIC: 'basic',
  ENTERPRISE: 'enterprise',
  format: value => {
    switch (value) {
      case CUSTOMER_TYPES.BASIC:
        return Localization.tr('Basic')

      case CUSTOMER_TYPES.ENTERPRISE:
        return Localization.tr('Enterprise')

      default:
        return Localization.tr('Unknown')
    }
  },
  values: () => {
    const customerTypes = Object.keys(CUSTOMER_TYPES)
      .filter(obj => typeof CUSTOMER_TYPES[obj] === 'string')
      .map(obj => {
        return {
          label: CUSTOMER_TYPES.format(CUSTOMER_TYPES[obj]),
          value: CUSTOMER_TYPES[obj]
        }
      })

    return customerTypes
  }
}

export class CustomerModel extends Model {
  constructor(c = {}) {
    super(c)
    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: c.name
    })

    this.type = new TextProperty({
      label: Localization.tr('Plan'),
      value: c.type,
      formatValue: value => CUSTOMER_TYPES.format(value)
    })

    this.logoUrl = new TextProperty({
      value: c.logo
    })

    this.salesforceNumber = new TextProperty({
      label: Localization.tr('Salesforce number'),
      value: c.salesforceNumber
    })

    this.createdDate = new DateTimeProperty({
      label: Localization.tr('Created'),
      value: c.createdDate
    })
  }

  isEnterprise() {
    return this.type.value === CUSTOMER_TYPES.ENTERPRISE
  }
}
