import { CalendarDates } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Localization } from '../../../libs/localization/localization.js'
import { Property } from './property.js'

export class CalendarDateProperty extends Property {
  constructor({ value, label, formattedDefault = '' } = {}) {
    super({
      value: CalendarDates.from({
        source: value
      }),
      label,
      formatValue: v =>
        CalendarDates.format({
          calendarDate: v,
          tz: Localization.getCurrentTimezone()
        })
    })

    if (!this.formattedValue) {
      this.formattedValue = formattedDefault
    }
  }
}
