import { I18nTranslate } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

export class Countries {
  constructor({ language }) {
    this._language = language
    this._memoizedCountries = null
  }

  get list() {
    if (!this._memoizedCountries) {
      this._memoizedCountries = this._computeCountries({ language: this._language })
    }

    return this._memoizedCountries
  }

  _computeCountries({ language }) {
    return [
      {
        code: 'abw',
        label: I18nTranslate.to(language).tr('Aruba')
      },
      {
        code: 'afg',
        label: I18nTranslate.to(language).tr('Afghanistan')
      },
      {
        code: 'ago',
        label: I18nTranslate.to(language).tr('Angola')
      },
      {
        code: 'aia',
        label: I18nTranslate.to(language).tr('Anguilla')
      },
      {
        code: 'ala',
        label: I18nTranslate.to(language).tr('Åland Islands')
      },
      {
        code: 'alb',
        label: I18nTranslate.to(language).tr('Albania')
      },
      {
        code: 'and',
        label: I18nTranslate.to(language).tr('Andorra')
      },
      {
        code: 'are',
        label: I18nTranslate.to(language).tr('United Arab Emirates')
      },
      {
        code: 'arg',
        label: I18nTranslate.to(language).tr('Argentina')
      },
      {
        code: 'arm',
        label: I18nTranslate.to(language).tr('Armenia')
      },
      {
        code: 'asm',
        label: I18nTranslate.to(language).tr('American Samoa')
      },
      {
        code: 'ata',
        label: I18nTranslate.to(language).tr('Antarctica')
      },
      {
        code: 'atf',
        label: I18nTranslate.to(language).tr('French Southern Territories')
      },
      {
        code: 'atg',
        label: I18nTranslate.to(language).tr('Antigua and Barbuda')
      },
      {
        code: 'aus',
        label: I18nTranslate.to(language).tr('Australia')
      },
      {
        code: 'aut',
        label: I18nTranslate.to(language).tr('Austria')
      },
      {
        code: 'aze',
        label: I18nTranslate.to(language).tr('Azerbaijan')
      },
      {
        code: 'bdi',
        label: I18nTranslate.to(language).tr('Burundi')
      },
      {
        code: 'bel',
        label: I18nTranslate.to(language).tr('Belgium')
      },
      {
        code: 'ben',
        label: I18nTranslate.to(language).tr('Benin')
      },
      {
        code: 'bes',
        label: I18nTranslate.to(language).tr('Bonaire, Sint Eustatius and Saba')
      },
      {
        code: 'bfa',
        label: I18nTranslate.to(language).tr('Burkina Faso')
      },
      {
        code: 'bgd',
        label: I18nTranslate.to(language).tr('Bangladesh')
      },
      {
        code: 'bgr',
        label: I18nTranslate.to(language).tr('Bulgaria')
      },
      {
        code: 'bhr',
        label: I18nTranslate.to(language).tr('Bahrain')
      },
      {
        code: 'bhs',
        label: I18nTranslate.to(language).tr('Bahamas')
      },
      {
        code: 'bih',
        label: I18nTranslate.to(language).tr('Bosnia and Herzegovina')
      },
      {
        code: 'blm',
        label: I18nTranslate.to(language).tr('Saint Barthélemy')
      },
      {
        code: 'blr',
        label: I18nTranslate.to(language).tr('Belarus')
      },
      {
        code: 'blz',
        label: I18nTranslate.to(language).tr('Belize')
      },
      {
        code: 'bmu',
        label: I18nTranslate.to(language).tr('Bermuda')
      },
      {
        code: 'bol',
        label: I18nTranslate.to(language).tr('Bolivia, Plurinational State of')
      },
      {
        code: 'bra',
        label: I18nTranslate.to(language).tr('Brazil')
      },
      {
        code: 'brb',
        label: I18nTranslate.to(language).tr('Barbados')
      },
      {
        code: 'brn',
        label: I18nTranslate.to(language).tr('Brunei Darussalam')
      },
      {
        code: 'btn',
        label: I18nTranslate.to(language).tr('Bhutan')
      },
      {
        code: 'bvt',
        label: I18nTranslate.to(language).tr('Bouvet Island')
      },
      {
        code: 'bwa',
        label: I18nTranslate.to(language).tr('Botswana')
      },
      {
        code: 'caf',
        label: I18nTranslate.to(language).tr('Central African Republic')
      },
      {
        code: 'can',
        label: I18nTranslate.to(language).tr('Canada')
      },
      {
        code: 'cck',
        label: I18nTranslate.to(language).tr('Cocos (Keeling) Islands')
      },
      {
        code: 'che',
        label: I18nTranslate.to(language).tr('Switzerland')
      },
      {
        code: 'chl',
        label: I18nTranslate.to(language).tr('Chile')
      },
      {
        code: 'chn',
        label: I18nTranslate.to(language).tr('China')
      },
      {
        code: 'civ',
        label: I18nTranslate.to(language).tr("Côte d'Ivoire")
      },
      {
        code: 'cmr',
        label: I18nTranslate.to(language).tr('Cameroon')
      },
      {
        code: 'cod',
        label: I18nTranslate.to(language).tr('Congo, The Democratic Republic of the')
      },
      {
        code: 'cog',
        label: I18nTranslate.to(language).tr('Congo')
      },
      {
        code: 'cok',
        label: I18nTranslate.to(language).tr('Cook Islands')
      },
      {
        code: 'col',
        label: I18nTranslate.to(language).tr('Colombia')
      },
      {
        code: 'com',
        label: I18nTranslate.to(language).tr('Comoros')
      },
      {
        code: 'cpv',
        label: I18nTranslate.to(language).tr('Cabo Verde')
      },
      {
        code: 'cri',
        label: I18nTranslate.to(language).tr('Costa Rica')
      },
      {
        code: 'cub',
        label: I18nTranslate.to(language).tr('Cuba')
      },
      {
        code: 'cuw',
        label: I18nTranslate.to(language).tr('Curaçao')
      },
      {
        code: 'cxr',
        label: I18nTranslate.to(language).tr('Christmas Island')
      },
      {
        code: 'cym',
        label: I18nTranslate.to(language).tr('Cayman Islands')
      },
      {
        code: 'cyp',
        label: I18nTranslate.to(language).tr('Cyprus')
      },
      {
        code: 'cze',
        label: I18nTranslate.to(language).tr('Czechia')
      },
      {
        code: 'deu',
        label: I18nTranslate.to(language).tr('Germany')
      },
      {
        code: 'dji',
        label: I18nTranslate.to(language).tr('Djibouti')
      },
      {
        code: 'dma',
        label: I18nTranslate.to(language).tr('Dominica')
      },
      {
        code: 'dnk',
        label: I18nTranslate.to(language).tr('Denmark')
      },
      {
        code: 'dom',
        label: I18nTranslate.to(language).tr('Dominican Republic')
      },
      {
        code: 'dza',
        label: I18nTranslate.to(language).tr('Algeria')
      },
      {
        code: 'ecu',
        label: I18nTranslate.to(language).tr('Ecuador')
      },
      {
        code: 'egy',
        label: I18nTranslate.to(language).tr('Egypt')
      },
      {
        code: 'eri',
        label: I18nTranslate.to(language).tr('Eritrea')
      },
      {
        code: 'esh',
        label: I18nTranslate.to(language).tr('Western Sahara')
      },
      {
        code: 'esp',
        label: I18nTranslate.to(language).tr('Spain')
      },
      {
        code: 'est',
        label: I18nTranslate.to(language).tr('Estonia')
      },
      {
        code: 'eth',
        label: I18nTranslate.to(language).tr('Ethiopia')
      },
      {
        code: 'fin',
        label: I18nTranslate.to(language).tr('Finland')
      },
      {
        code: 'fji',
        label: I18nTranslate.to(language).tr('Fiji')
      },
      {
        code: 'flk',
        label: I18nTranslate.to(language).tr('Falkland Islands (Malvinas)')
      },
      {
        code: 'fra',
        label: I18nTranslate.to(language).tr('France')
      },
      {
        code: 'fro',
        label: I18nTranslate.to(language).tr('Faroe Islands')
      },
      {
        code: 'fsm',
        label: I18nTranslate.to(language).tr('Micronesia, Federated States of')
      },
      {
        code: 'gab',
        label: I18nTranslate.to(language).tr('Gabon')
      },
      {
        code: 'gbr',
        label: I18nTranslate.to(language).tr('United Kingdom')
      },
      {
        code: 'geo',
        label: I18nTranslate.to(language).tr('Georgia')
      },
      {
        code: 'ggy',
        label: I18nTranslate.to(language).tr('Guernsey')
      },
      {
        code: 'gha',
        label: I18nTranslate.to(language).tr('Ghana')
      },
      {
        code: 'gib',
        label: I18nTranslate.to(language).tr('Gibraltar')
      },
      {
        code: 'gin',
        label: I18nTranslate.to(language).tr('Guinea')
      },
      {
        code: 'glp',
        label: I18nTranslate.to(language).tr('Guadeloupe')
      },
      {
        code: 'gmb',
        label: I18nTranslate.to(language).tr('Gambia')
      },
      {
        code: 'gnb',
        label: I18nTranslate.to(language).tr('Guinea-Bissau')
      },
      {
        code: 'gnq',
        label: I18nTranslate.to(language).tr('Equatorial Guinea')
      },
      {
        code: 'grc',
        label: I18nTranslate.to(language).tr('Greece')
      },
      {
        code: 'grd',
        label: I18nTranslate.to(language).tr('Grenada')
      },
      {
        code: 'grl',
        label: I18nTranslate.to(language).tr('Greenland')
      },
      {
        code: 'gtm',
        label: I18nTranslate.to(language).tr('Guatemala')
      },
      {
        code: 'guf',
        label: I18nTranslate.to(language).tr('French Guiana')
      },
      {
        code: 'gum',
        label: I18nTranslate.to(language).tr('Guam')
      },
      {
        code: 'guy',
        label: I18nTranslate.to(language).tr('Guyana')
      },
      {
        code: 'hkg',
        label: I18nTranslate.to(language).tr('Hong Kong')
      },
      {
        code: 'hmd',
        label: I18nTranslate.to(language).tr('Heard Island and McDonald Islands')
      },
      {
        code: 'hnd',
        label: I18nTranslate.to(language).tr('Honduras')
      },
      {
        code: 'hrv',
        label: I18nTranslate.to(language).tr('Croatia')
      },
      {
        code: 'hti',
        label: I18nTranslate.to(language).tr('Haiti')
      },
      {
        code: 'hun',
        label: I18nTranslate.to(language).tr('Hungary')
      },
      {
        code: 'idn',
        label: I18nTranslate.to(language).tr('Indonesia')
      },
      {
        code: 'imn',
        label: I18nTranslate.to(language).tr('Isle of Man')
      },
      {
        code: 'ind',
        label: I18nTranslate.to(language).tr('India')
      },
      {
        code: 'iot',
        label: I18nTranslate.to(language).tr('British Indian Ocean Territory')
      },
      {
        code: 'irl',
        label: I18nTranslate.to(language).tr('Ireland')
      },
      {
        code: 'irn',
        label: I18nTranslate.to(language).tr('Iran, Islamic Republic of')
      },
      {
        code: 'irq',
        label: I18nTranslate.to(language).tr('Iraq')
      },
      {
        code: 'isl',
        label: I18nTranslate.to(language).tr('Iceland')
      },
      {
        code: 'isr',
        label: I18nTranslate.to(language).tr('Israel')
      },
      {
        code: 'ita',
        label: I18nTranslate.to(language).tr('Italy')
      },
      {
        code: 'jam',
        label: I18nTranslate.to(language).tr('Jamaica')
      },
      {
        code: 'jey',
        label: I18nTranslate.to(language).tr('Jersey')
      },
      {
        code: 'jor',
        label: I18nTranslate.to(language).tr('Jordan')
      },
      {
        code: 'jpn',
        label: I18nTranslate.to(language).tr('Japan')
      },
      {
        code: 'kaz',
        label: I18nTranslate.to(language).tr('Kazakhstan')
      },
      {
        code: 'ken',
        label: I18nTranslate.to(language).tr('Kenya')
      },
      {
        code: 'kgz',
        label: I18nTranslate.to(language).tr('Kyrgyzstan')
      },
      {
        code: 'khm',
        label: I18nTranslate.to(language).tr('Cambodia')
      },
      {
        code: 'kir',
        label: I18nTranslate.to(language).tr('Kiribati')
      },
      {
        code: 'kna',
        label: I18nTranslate.to(language).tr('Saint Kitts and Nevis')
      },
      {
        code: 'kor',
        label: I18nTranslate.to(language).tr('Korea, Republic of')
      },
      {
        code: 'kwt',
        label: I18nTranslate.to(language).tr('Kuwait')
      },
      {
        code: 'lao',
        label: I18nTranslate.to(language).tr("Lao People's Democratic Republic")
      },
      {
        code: 'lbn',
        label: I18nTranslate.to(language).tr('Lebanon')
      },
      {
        code: 'lbr',
        label: I18nTranslate.to(language).tr('Liberia')
      },
      {
        code: 'lby',
        label: I18nTranslate.to(language).tr('Libya')
      },
      {
        code: 'lca',
        label: I18nTranslate.to(language).tr('Saint Lucia')
      },
      {
        code: 'lie',
        label: I18nTranslate.to(language).tr('Liechtenstein')
      },
      {
        code: 'lka',
        label: I18nTranslate.to(language).tr('Sri Lanka')
      },
      {
        code: 'lso',
        label: I18nTranslate.to(language).tr('Lesotho')
      },
      {
        code: 'ltu',
        label: I18nTranslate.to(language).tr('Lithuania')
      },
      {
        code: 'lux',
        label: I18nTranslate.to(language).tr('Luxembourg')
      },
      {
        code: 'lva',
        label: I18nTranslate.to(language).tr('Latvia')
      },
      {
        code: 'mac',
        label: I18nTranslate.to(language).tr('Macao')
      },
      {
        code: 'maf',
        label: I18nTranslate.to(language).tr('Saint Martin (French part)')
      },
      {
        code: 'mar',
        label: I18nTranslate.to(language).tr('Morocco')
      },
      {
        code: 'mco',
        label: I18nTranslate.to(language).tr('Monaco')
      },
      {
        code: 'mda',
        label: I18nTranslate.to(language).tr('Moldova, Republic of')
      },
      {
        code: 'mdg',
        label: I18nTranslate.to(language).tr('Madagascar')
      },
      {
        code: 'mdv',
        label: I18nTranslate.to(language).tr('Maldives')
      },
      {
        code: 'mex',
        label: I18nTranslate.to(language).tr('Mexico')
      },
      {
        code: 'mhl',
        label: I18nTranslate.to(language).tr('Marshall Islands')
      },
      {
        code: 'mkd',
        label: I18nTranslate.to(language).tr('Macedonia, Republic of')
      },
      {
        code: 'mli',
        label: I18nTranslate.to(language).tr('Mali')
      },
      {
        code: 'mlt',
        label: I18nTranslate.to(language).tr('Malta')
      },
      {
        code: 'mmr',
        label: I18nTranslate.to(language).tr('Myanmar')
      },
      {
        code: 'mne',
        label: I18nTranslate.to(language).tr('Montenegro')
      },
      {
        code: 'mng',
        label: I18nTranslate.to(language).tr('Mongolia')
      },
      {
        code: 'mnp',
        label: I18nTranslate.to(language).tr('Northern Mariana Islands')
      },
      {
        code: 'moz',
        label: I18nTranslate.to(language).tr('Mozambique')
      },
      {
        code: 'mrt',
        label: I18nTranslate.to(language).tr('Mauritania')
      },
      {
        code: 'msr',
        label: I18nTranslate.to(language).tr('Montserrat')
      },
      {
        code: 'mtq',
        label: I18nTranslate.to(language).tr('Martinique')
      },
      {
        code: 'mus',
        label: I18nTranslate.to(language).tr('Mauritius')
      },
      {
        code: 'mwi',
        label: I18nTranslate.to(language).tr('Malawi')
      },
      {
        code: 'mys',
        label: I18nTranslate.to(language).tr('Malaysia')
      },
      {
        code: 'myt',
        label: I18nTranslate.to(language).tr('Mayotte')
      },
      {
        code: 'nam',
        label: I18nTranslate.to(language).tr('Namibia')
      },
      {
        code: 'ncl',
        label: I18nTranslate.to(language).tr('New Caledonia')
      },
      {
        code: 'ner',
        label: I18nTranslate.to(language).tr('Niger')
      },
      {
        code: 'nfk',
        label: I18nTranslate.to(language).tr('Norfolk Island')
      },
      {
        code: 'nga',
        label: I18nTranslate.to(language).tr('Nigeria')
      },
      {
        code: 'nic',
        label: I18nTranslate.to(language).tr('Nicaragua')
      },
      {
        code: 'niu',
        label: I18nTranslate.to(language).tr('Niue')
      },
      {
        code: 'nld',
        label: I18nTranslate.to(language).tr('Netherlands')
      },
      {
        code: 'nor',
        label: I18nTranslate.to(language).tr('Norway')
      },
      {
        code: 'npl',
        label: I18nTranslate.to(language).tr('Nepal')
      },
      {
        code: 'nru',
        label: I18nTranslate.to(language).tr('Nauru')
      },
      {
        code: 'nzl',
        label: I18nTranslate.to(language).tr('New Zealand')
      },
      {
        code: 'omn',
        label: I18nTranslate.to(language).tr('Oman')
      },
      {
        code: 'pak',
        label: I18nTranslate.to(language).tr('Pakistan')
      },
      {
        code: 'pan',
        label: I18nTranslate.to(language).tr('Panama')
      },
      {
        code: 'pcn',
        label: I18nTranslate.to(language).tr('Pitcairn')
      },
      {
        code: 'per',
        label: I18nTranslate.to(language).tr('Peru')
      },
      {
        code: 'phl',
        label: I18nTranslate.to(language).tr('Philippines')
      },
      {
        code: 'plw',
        label: I18nTranslate.to(language).tr('Palau')
      },
      {
        code: 'png',
        label: I18nTranslate.to(language).tr('Papua New Guinea')
      },
      {
        code: 'pol',
        label: I18nTranslate.to(language).tr('Poland')
      },
      {
        code: 'pri',
        label: I18nTranslate.to(language).tr('Puerto Rico')
      },
      {
        code: 'prk',
        label: I18nTranslate.to(language).tr("Korea, Democratic People's Republic of")
      },
      {
        code: 'prt',
        label: I18nTranslate.to(language).tr('Portugal')
      },
      {
        code: 'pry',
        label: I18nTranslate.to(language).tr('Paraguay')
      },
      {
        code: 'pse',
        label: I18nTranslate.to(language).tr('Palestine, State of')
      },
      {
        code: 'pyf',
        label: I18nTranslate.to(language).tr('French Polynesia')
      },
      {
        code: 'qat',
        label: I18nTranslate.to(language).tr('Qatar')
      },
      {
        code: 'reu',
        label: I18nTranslate.to(language).tr('Réunion')
      },
      {
        code: 'rou',
        label: I18nTranslate.to(language).tr('Romania')
      },
      {
        code: 'rus',
        label: I18nTranslate.to(language).tr('Russian Federation')
      },
      {
        code: 'rwa',
        label: I18nTranslate.to(language).tr('Rwanda')
      },
      {
        code: 'sau',
        label: I18nTranslate.to(language).tr('Saudi Arabia')
      },
      {
        code: 'sdn',
        label: I18nTranslate.to(language).tr('Sudan')
      },
      {
        code: 'sen',
        label: I18nTranslate.to(language).tr('Senegal')
      },
      {
        code: 'sgp',
        label: I18nTranslate.to(language).tr('Singapore')
      },
      {
        code: 'sgs',
        label: I18nTranslate.to(language).tr('South Georgia and the South Sandwich Islands')
      },
      {
        code: 'shn',
        label: I18nTranslate.to(language).tr('Saint Helena, Ascension and Tristan da Cunha')
      },
      {
        code: 'sjm',
        label: I18nTranslate.to(language).tr('Svalbard and Jan Mayen')
      },
      {
        code: 'slb',
        label: I18nTranslate.to(language).tr('Solomon Islands')
      },
      {
        code: 'sle',
        label: I18nTranslate.to(language).tr('Sierra Leone')
      },
      {
        code: 'slv',
        label: I18nTranslate.to(language).tr('El Salvador')
      },
      {
        code: 'smr',
        label: I18nTranslate.to(language).tr('San Marino')
      },
      {
        code: 'som',
        label: I18nTranslate.to(language).tr('Somalia')
      },
      {
        code: 'spm',
        label: I18nTranslate.to(language).tr('Saint Pierre and Miquelon')
      },
      {
        code: 'srb',
        label: I18nTranslate.to(language).tr('Serbia')
      },
      {
        code: 'ssd',
        label: I18nTranslate.to(language).tr('South Sudan')
      },
      {
        code: 'stp',
        label: I18nTranslate.to(language).tr('Sao Tome and Principe')
      },
      {
        code: 'sur',
        label: I18nTranslate.to(language).tr('Surilabel')
      },
      {
        code: 'svk',
        label: I18nTranslate.to(language).tr('Slovakia')
      },
      {
        code: 'svn',
        label: I18nTranslate.to(language).tr('Slovenia')
      },
      {
        code: 'swe',
        label: I18nTranslate.to(language).tr('Sweden')
      },
      {
        code: 'swz',
        label: I18nTranslate.to(language).tr('Swaziland')
      },
      {
        code: 'sxm',
        label: I18nTranslate.to(language).tr('Sint Maarten (Dutch part)')
      },
      {
        code: 'syc',
        label: I18nTranslate.to(language).tr('Seychelles')
      },
      {
        code: 'syr',
        label: I18nTranslate.to(language).tr('Syrian Arab Republic')
      },
      {
        code: 'tca',
        label: I18nTranslate.to(language).tr('Turks and Caicos Islands')
      },
      {
        code: 'tcd',
        label: I18nTranslate.to(language).tr('Chad')
      },
      {
        code: 'tgo',
        label: I18nTranslate.to(language).tr('Togo')
      },
      {
        code: 'tha',
        label: I18nTranslate.to(language).tr('Thailand')
      },
      {
        code: 'tjk',
        label: I18nTranslate.to(language).tr('Tajikistan')
      },
      {
        code: 'tkl',
        label: I18nTranslate.to(language).tr('Tokelau')
      },
      {
        code: 'tkm',
        label: I18nTranslate.to(language).tr('Turkmenistan')
      },
      {
        code: 'tls',
        label: I18nTranslate.to(language).tr('Timor-Leste')
      },
      {
        code: 'ton',
        label: I18nTranslate.to(language).tr('Tonga')
      },
      {
        code: 'tto',
        label: I18nTranslate.to(language).tr('Trinidad and Tobago')
      },
      {
        code: 'tun',
        label: I18nTranslate.to(language).tr('Tunisia')
      },
      {
        code: 'tur',
        label: I18nTranslate.to(language).tr('Turkey')
      },
      {
        code: 'tuv',
        label: I18nTranslate.to(language).tr('Tuvalu')
      },
      {
        code: 'twn',
        label: I18nTranslate.to(language).tr('Taiwan, Province of China')
      },
      {
        code: 'tza',
        label: I18nTranslate.to(language).tr('Tanzania, United Republic of')
      },
      {
        code: 'uga',
        label: I18nTranslate.to(language).tr('Uganda')
      },
      {
        code: 'ukr',
        label: I18nTranslate.to(language).tr('Ukraine')
      },
      {
        code: 'umi',
        label: I18nTranslate.to(language).tr('United States Minor Outlying Islands')
      },
      {
        code: 'ury',
        label: I18nTranslate.to(language).tr('Uruguay')
      },
      {
        code: 'usa',
        label: I18nTranslate.to(language).tr('United States')
      },
      {
        code: 'uzb',
        label: I18nTranslate.to(language).tr('Uzbekistan')
      },
      {
        code: 'vat',
        label: I18nTranslate.to(language).tr('Holy See (Vatican City State)')
      },
      {
        code: 'vct',
        label: I18nTranslate.to(language).tr('Saint Vincent and the Grenadines')
      },
      {
        code: 'ven',
        label: I18nTranslate.to(language).tr('Venezuela, Bolivarian Republic of')
      },
      {
        code: 'vgb',
        label: I18nTranslate.to(language).tr('Virgin Islands, British')
      },
      {
        code: 'vir',
        label: I18nTranslate.to(language).tr('Virgin Islands, U.S.')
      },
      {
        code: 'vnm',
        label: I18nTranslate.to(language).tr('Viet Nam')
      },
      {
        code: 'vut',
        label: I18nTranslate.to(language).tr('Vanuatu')
      },
      {
        code: 'wlf',
        label: I18nTranslate.to(language).tr('Wallis and Futuna')
      },
      {
        code: 'wsm',
        label: I18nTranslate.to(language).tr('Samoa')
      },
      {
        code: 'yem',
        label: I18nTranslate.to(language).tr('Yemen')
      },
      {
        code: 'zaf',
        label: I18nTranslate.to(language).tr('South Africa')
      },
      {
        code: 'zmb',
        label: I18nTranslate.to(language).tr('Zambia')
      },
      {
        code: 'zwe',
        label: I18nTranslate.to(language).tr('Zimbabwe')
      }
    ]
  }
}
