import { Property } from './property.js'

export class TextProperty extends Property {
  constructor({ value, label, formatValue, description } = {}) {
    super({
      value: value || '',
      label,
      formatValue
    })

    this.description = description || ''
  }
}
