import { CurrencyProperty, NumberProperty, TextProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class AnimalAmountPerWeightCostsModel extends Model {
  constructor(a = {}) {
    super(a)
    this.amountPerWeight = new NumberProperty({
      label: Localization.tr('Count/kg'),
      value: a.amountPerWeight,
      unit: Localization.tr('kg'),
      step: 1,
      formatValueWithUnit: (formattedValue, unit) => {
        return `${a.label}/${unit}`
      }
    })

    this.pricePerWeight = new CurrencyProperty({
      label: Localization.tr('Price/kg'),
      value: a.pricePerWeight,
      currency: a.currency,
      unit: Localization.tr('kg')
    })

    this.currency = new TextProperty({
      label: Localization.tr('Currency'),
      value: a.currency
    })
  }
}
