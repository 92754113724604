import { Localization } from '../../../libs/localization/localization.js'
import { Log } from '../../../libs/log.js'
import { Numbers } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Property } from './property.js'

export class CurrencyProperty extends Property {
  static defaultFormatFloatValue(value) {
    return value != null
      ? Localization.toLocaleFixedString(value, 2) // eslint-disable-line no-magic-numbers
      : ''
  }

  static defaultFormatValueWithCurrency(currency, value, unit, options = {}) {
    const currencyFormatter = new Intl.NumberFormat(
      Localization.currentLanguage,
      Object.assign(
        {
          currency,
          style: 'currency'
        },
        options
      )
    )
    let formattedValue = currencyFormatter.format(value, options)

    if (unit) {
      formattedValue += `/${unit}`
    }

    return formattedValue
  }

  static getFormattedValueWithUnit({ value, formattedValue, currency, unit, formatValueWithCurrency }) {
    let formattedValueWithUnit = ''

    if (value) {
      formattedValueWithUnit = formatValueWithCurrency
        ? formatValueWithCurrency(formattedValue, currency, unit)
        : CurrencyProperty.defaultFormatValueWithCurrency(currency, value, unit)
    }

    return formattedValueWithUnit
  }

  static getFormattedNoDecimalsValueWithUnit({ value, formattedValue, currency, unit, formatValueWithCurrency }) {
    let formattedNoDecimalsValueWithUnit = ''

    if (value) {
      if (formatValueWithCurrency) {
        formattedNoDecimalsValueWithUnit = formatValueWithCurrency(formattedValue, currency, unit, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      } else {
        formattedNoDecimalsValueWithUnit = CurrencyProperty.defaultFormatValueWithCurrency(currency, value, unit, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    }

    return formattedNoDecimalsValueWithUnit
  }

  constructor({ value = null, label, currency, formatValue, formatValueWithCurrency, unit = '' } = {}) {
    super({
      label,
      value,
      formatValue: formatValue || CurrencyProperty.defaultFormatFloatValue
    })

    this.currency = currency || 'USD'
    this.step = 0.01
    const currencyObj = Localization.getCurrencyFromCode(this.currency.toLowerCase())
    this.roundedValue = Numbers.getRoundedValue(this.value, this.step)

    if (!currencyObj) {
      Log.error(`${this.currency} currency does not exist`)
    }

    this.currencySymbol = currencyObj ? currencyObj.symbol : ''

    if (unit) {
      this.unit = unit
      this.currencySymbolUnit = `${this.currencySymbol}/${unit}`
    }

    const formatValueParams = {
      value: this.value,
      formattedValue: this.formattedValue,
      currency: this.currency,
      unit: this.unit,
      formatValueWithCurrency
    }
    this.formattedValueWithUnit = CurrencyProperty.getFormattedValueWithUnit(formatValueParams)
    this.formattedNoDecimalsValueWithUnit = CurrencyProperty.getFormattedNoDecimalsValueWithUnit(formatValueParams)
  }
}
