const xpertseaCustomerId = 'e825ea24-39c8-11e9-b9ac-fe3bf6d3d247'

export const Configuration = {
  environment: 'PRODUCTION',
  analytics: {
    enabled: true,
    segmentId: 'yiFWEUYwUswVlR0Yd1MQr4n69nfDEKU9',
    sentryDsn: 'https://01031f0e5d57454a8cffd53fcd70688b@sentry.io/1481512'
  },
  persistence: {
    version: 23
  },
  hosts: {
    deepwater: 'https://deepwater.xpertsea.com',
    event: 'wss://event.deepwater.xpertsea.com',
    status: 'https://xpertsea.statuspage.io'
  },
  flags: {},
  xpertseaCustomerId
}
