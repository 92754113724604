import { Localization } from '../../../libs/localization/localization.js'

export class DeepwaterSessionPictureMapper {
  static convertPictureMetas(metas) {
    return (metas || []).map(meta => {
      return meta.key != null ? { value: DeepwaterSessionPictureMapper.getMetasValue(meta.key) } : { value: meta.value }
    })
  }

  static getMetasValue(metaKey) {
    switch (metaKey) {
      case 'preview_image': {
        return Localization.tr('Preview')
      }

      case 'blobs_image': {
        return Localization.tr('Blobs')
      }

      case 'thresholded_image': {
        return Localization.tr('Thresholded')
      }

      case 'grayscaled_image': {
        return Localization.tr('Grayscaled')
      }

      case 'maskrcnn_weight': {
        return Localization.tr('Maskrcnn weight')
      }

      case 'maskrcnn': {
        return Localization.tr('Maskrcnn')
      }

      case 'post_processing_image': {
        return Localization.tr('Post processing image')
      }

      case 'unet': {
        return Localization.tr('Unet')
      }

      case '': {
        return Localization.tr('Original')
      }

      default: {
        if (!isNaN(metaKey)) {
          return Localization.tr('Sample #${pictureName}', {
            '${pictureName}': Number(metaKey) + 1
          })
        }

        return metaKey
      }
    }
  }
}
