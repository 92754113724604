import { AnimalAmountPerWeightCostsModel } from '../animal-amount-per-weight-costs-model.js'
import { Strings } from '../../../libs/strings.js'

export class DeepwaterAnimalAmountPerWeightCostsMapper {
  static convert(amountPerWeightPrices, currency) {
    const models = []
    const range = amountPerWeightPrices.length

    for (let i = 0; i < range; i++) {
      const animalAmountPerWeight = amountPerWeightPrices[i]
      const nextAnimalAmountPerWeight = amountPerWeightPrices[i + 1]
      const { amountPerWeight, pricePerWeight } = animalAmountPerWeight

      const label = Strings.rangeLabel(
        i,
        animalAmountPerWeight.amountPerWeight,
        nextAnimalAmountPerWeight ? nextAnimalAmountPerWeight.amountPerWeight : null
      )

      models.push(
        new AnimalAmountPerWeightCostsModel({
          amountPerWeight,
          pricePerWeight,
          currency,
          label
        })
      )
    }

    return models
  }
}
