import { AppVersion } from '../../version.js'
import { UUID } from '../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

/**
 * Refs: https://segment.com/docs/sources/website/analytics.js/
 */
class SegmentAnalytics {
  init(analyticsId) {
    this._getAnalytics().load(analyticsId)
    // first call needed for some destinations
    // https://segment.com/docs/sources/website/analytics.js/#page
    this._getAnalytics().page('system', 'analytics-config')

    this._initializeSessionId()
  }

  identify(user) {
    if (!user || !this._isConfigured()) {
      return
    }

    const traits = {
      appVersion: AppVersion,
      type: user.getType(),
      language: user.language.value,
      timezone: user.timezone.value,
      sessionId: this.sessionId
    }

    this._getAnalytics().identify(user.id.value, traits)
  }

  track(...args) {
    if (!this._isConfigured()) {
      return
    }

    const augmentedArgs = this._injectSessionId(...args)
    this._getAnalytics().track(...augmentedArgs)
  }

  page(...args) {
    if (!this._isConfigured()) {
      return
    }

    const augmentedArgs = this._injectSessionId(...args)
    this._getAnalytics().page(...augmentedArgs)
  }

  clear() {
    if (!this._isConfigured()) {
      return
    }

    this._getAnalytics().reset()
    this._initializeSessionId()
  }

  _injectSessionId(...args) {
    if (args.length > 1) {
      args[1].sessionId = this.sessionId
    } else {
      args.push({ sessionId: this.sessionId })
    }

    return args
  }

  _initializeSessionId() {
    this.sessionId = UUID.uuidv4()
  }

  _isConfigured() {
    return Boolean(this._getAnalytics())
  }

  _getAnalytics() {
    return window.analytics
  }
}

export const Analytics = new SegmentAnalytics()
