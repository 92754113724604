import { DateTime } from '../../node_modules/luxon/src/luxon.js'

export class Feature {
  static isAscEnabled(organization, application) {
    const ascEnabled = Boolean(organization) && organization.ascEnabled.value
    const supportsAsc = Boolean(application) && application.supportsAsc.value
    return ascEnabled && supportsAsc
  }

  static isFeedRxEnabled(organization, application) {
    const feedRxEnabled = Boolean(organization) && organization.feedRxEnabled.value
    const supportsFeedRx = Boolean(application) && application.supportsFeedRx.value
    return supportsFeedRx && feedRxEnabled
  }

  static isValidAuthorization(authorization) {
    if (!authorization) {
      return false
    }

    const expirationDate =
      authorization.expirationDate instanceof Date
        ? authorization.expirationDate
        : DateTime.fromISO(authorization.expirationDate)
    return expirationDate >= DateTime.local()
  }
}
