import { I18nTranslate } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

export class Currencies {
  constructor({ language }) {
    this._language = language
    this._memoizedCurrencies = null
  }

  get list() {
    if (!this._memoizedCurrencies) {
      this._memoizedCurrencies = this._computeCurrencies({ language: this._language })
    }

    return this._memoizedCurrencies
  }

  _computeCurrencies({ language }) {
    return [
      {
        code: 'aed',
        label: I18nTranslate.to(language).tr('UAE Dirham'),
        symbol: 'AED'
      },
      {
        code: 'afn',
        label: I18nTranslate.to(language).tr('Afghani'),
        symbol: 'AFN'
      },
      {
        code: 'all',
        label: I18nTranslate.to(language).tr('Lek'),
        symbol: 'ALL'
      },
      {
        code: 'amd',
        label: I18nTranslate.to(language).tr('Armenian Dram'),
        symbol: 'AMD'
      },
      {
        code: 'ang',
        label: I18nTranslate.to(language).tr('Netherlands Antillean Guilder'),
        symbol: 'ANG'
      },
      {
        code: 'aoa',
        label: I18nTranslate.to(language).tr('Kwanza'),
        symbol: 'AOA'
      },
      {
        code: 'ars',
        label: I18nTranslate.to(language).tr('Argentine Peso'),
        symbol: 'ARS'
      },
      {
        code: 'aud',
        label: I18nTranslate.to(language).tr('Australian Dollar'),
        symbol: 'A$'
      },
      {
        code: 'awg',
        label: I18nTranslate.to(language).tr('Aruban Florin'),
        symbol: 'AWG'
      },
      {
        code: 'azn',
        label: I18nTranslate.to(language).tr('Azerbaijanian Manat'),
        symbol: 'AZN'
      },
      {
        code: 'bam',
        label: I18nTranslate.to(language).tr('Convertible Mark'),
        symbol: 'BAM'
      },
      {
        code: 'bbd',
        label: I18nTranslate.to(language).tr('Barbados Dollar'),
        symbol: 'BBD'
      },
      {
        code: 'bdt',
        label: I18nTranslate.to(language).tr('Taka'),
        symbol: 'BDT'
      },
      {
        code: 'bgn',
        label: I18nTranslate.to(language).tr('Bulgarian Lev'),
        symbol: 'BGN'
      },
      {
        code: 'bhd',
        label: I18nTranslate.to(language).tr('Bahraini Dinar'),
        symbol: 'BHD'
      },
      {
        code: 'bif',
        label: I18nTranslate.to(language).tr('Burundi Franc'),
        symbol: 'BIF'
      },
      {
        code: 'bmd',
        label: I18nTranslate.to(language).tr('Bermudian Dollar'),
        symbol: 'BMD'
      },
      {
        code: 'bnd',
        label: I18nTranslate.to(language).tr('Brunei Dollar'),
        symbol: 'BND'
      },
      {
        code: 'bob',
        label: I18nTranslate.to(language).tr('Boliviano'),
        symbol: 'BOB'
      },
      {
        code: 'brl',
        label: I18nTranslate.to(language).tr('Brazilian Real'),
        symbol: 'R$'
      },
      {
        code: 'bsd',
        label: I18nTranslate.to(language).tr('Bahamian Dollar'),
        symbol: 'BSD'
      },
      {
        code: 'btn',
        label: I18nTranslate.to(language).tr('Ngultrum'),
        symbol: 'BTN'
      },
      {
        code: 'bwp',
        label: I18nTranslate.to(language).tr('Pula'),
        symbol: 'BWP'
      },
      {
        code: 'byn',
        label: I18nTranslate.to(language).tr('Belarusian Ruble'),
        symbol: 'BYN'
      },
      {
        code: 'bzd',
        label: I18nTranslate.to(language).tr('Belize Dollar'),
        symbol: 'BZD'
      },
      {
        code: 'cad',
        label: I18nTranslate.to(language).tr('Canadian Dollar'),
        symbol: 'CA$'
      },
      {
        code: 'cdf',
        label: I18nTranslate.to(language).tr('Congolese Franc'),
        symbol: 'CDF'
      },
      {
        code: 'chf',
        label: I18nTranslate.to(language).tr('Swiss Franc'),
        symbol: 'CHF'
      },
      {
        code: 'clp',
        label: I18nTranslate.to(language).tr('Chilean Peso'),
        symbol: 'CLP'
      },
      {
        code: 'cny',
        label: I18nTranslate.to(language).tr('Yuan Renminbi'),
        symbol: 'CN¥'
      },
      {
        code: 'cop',
        label: I18nTranslate.to(language).tr('Colombian Peso'),
        symbol: 'COP'
      },
      {
        code: 'crc',
        label: I18nTranslate.to(language).tr('Costa Rican Colon'),
        symbol: 'CRC'
      },
      {
        code: 'cuc',
        label: I18nTranslate.to(language).tr('Peso Convertible'),
        symbol: 'CUC'
      },
      {
        code: 'cup',
        label: I18nTranslate.to(language).tr('Cuban Peso'),
        symbol: 'CUP'
      },
      {
        code: 'cve',
        label: I18nTranslate.to(language).tr('Cabo Verde Escudo'),
        symbol: 'CVE'
      },
      {
        code: 'czk',
        label: I18nTranslate.to(language).tr('Czech Koruna'),
        symbol: 'CZK'
      },
      {
        code: 'djf',
        label: I18nTranslate.to(language).tr('Djibouti Franc'),
        symbol: 'DJK'
      },
      {
        code: 'dkk',
        label: I18nTranslate.to(language).tr('Danish Krone'),
        symbol: 'DKK'
      },
      {
        code: 'dop',
        label: I18nTranslate.to(language).tr('Dominican Peso'),
        symbol: 'DOP'
      },
      {
        code: 'dzd',
        label: I18nTranslate.to(language).tr('Algerian Dinar'),
        symbol: 'DZD'
      },
      {
        code: 'egp',
        label: I18nTranslate.to(language).tr('Egyptian Pound'),
        symbol: 'EGP'
      },
      {
        code: 'ern',
        label: I18nTranslate.to(language).tr('Nakfa'),
        symbol: 'ERN'
      },
      {
        code: 'etb',
        label: I18nTranslate.to(language).tr('Ethiopian Birr'),
        symbol: 'ETB'
      },
      {
        code: 'eur',
        label: I18nTranslate.to(language).tr('Euro'),
        symbol: '€'
      },
      {
        code: 'fjd',
        label: I18nTranslate.to(language).tr('Fiji Dollar'),
        symbol: 'FJD'
      },
      {
        code: 'fkp',
        label: I18nTranslate.to(language).tr('Falkland Islands Pound'),
        symbol: 'FKP'
      },
      {
        code: 'gbp',
        label: I18nTranslate.to(language).tr('Pound Sterling'),
        symbol: '£'
      },
      {
        code: 'gel',
        label: I18nTranslate.to(language).tr('Lari'),
        symbol: 'GEL'
      },
      {
        code: 'ghs',
        label: I18nTranslate.to(language).tr('Ghana Cedi'),
        symbol: 'GHS'
      },
      {
        code: 'gip',
        label: I18nTranslate.to(language).tr('Gibraltar Pound'),
        symbol: 'GIP'
      },
      {
        code: 'gmd',
        label: I18nTranslate.to(language).tr('Dalasi'),
        symbol: 'GMD'
      },
      {
        code: 'gnf',
        label: I18nTranslate.to(language).tr('Guinea Franc'),
        symbol: 'GNF'
      },
      {
        code: 'gtq',
        label: I18nTranslate.to(language).tr('Quetzal'),
        symbol: 'GTQ'
      },
      {
        code: 'gyd',
        label: I18nTranslate.to(language).tr('Guyana Dollar'),
        symbol: 'GYD'
      },
      {
        code: 'hkd',
        label: I18nTranslate.to(language).tr('Hong Kong Dollar'),
        symbol: 'HK$'
      },
      {
        code: 'hnl',
        label: I18nTranslate.to(language).tr('Lempira'),
        symbol: 'HNL'
      },
      {
        code: 'hrk',
        label: I18nTranslate.to(language).tr('Kuna'),
        symbol: 'HRK'
      },
      {
        code: 'htg',
        label: I18nTranslate.to(language).tr('Gourde'),
        symbol: 'HTG'
      },
      {
        code: 'huf',
        label: I18nTranslate.to(language).tr('Forint'),
        symbol: 'HUF'
      },
      {
        code: 'idr',
        label: I18nTranslate.to(language).tr('Rupiah'),
        symbol: 'IDR'
      },
      {
        code: 'ils',
        label: I18nTranslate.to(language).tr('New Israeli Sheqel'),
        symbol: '₪'
      },
      {
        code: 'inr',
        label: I18nTranslate.to(language).tr('Indian Rupee'),
        symbol: '₹'
      },
      {
        code: 'iqd',
        label: I18nTranslate.to(language).tr('Iraqi Dinar'),
        symbol: 'IQD'
      },
      {
        code: 'irr',
        label: I18nTranslate.to(language).tr('Iranian Rial'),
        symbol: 'IRR'
      },
      {
        code: 'isk',
        label: I18nTranslate.to(language).tr('Iceland Krona'),
        symbol: 'ISK'
      },
      {
        code: 'jmd',
        label: I18nTranslate.to(language).tr('Jamaican Dollar'),
        symbol: 'JMD'
      },
      {
        code: 'jod',
        label: I18nTranslate.to(language).tr('Jordanian Dinar'),
        symbol: 'JOD'
      },
      {
        code: 'jpy',
        label: I18nTranslate.to(language).tr('Yen'),
        symbol: '¥'
      },
      {
        code: 'kes',
        label: I18nTranslate.to(language).tr('Kenyan Shilling'),
        symbol: 'KES'
      },
      {
        code: 'kgs',
        label: I18nTranslate.to(language).tr('Som'),
        symbol: 'KGS'
      },
      {
        code: 'khr',
        label: I18nTranslate.to(language).tr('Riel'),
        symbol: 'KHR'
      },
      {
        code: 'kmf',
        label: I18nTranslate.to(language).tr('Comoro Franc'),
        symbol: 'KMF'
      },
      {
        code: 'kpw',
        label: I18nTranslate.to(language).tr('North Korean Won'),
        symbol: 'KPW'
      },
      {
        code: 'krw',
        label: I18nTranslate.to(language).tr('Won'),
        symbol: '₩'
      },
      {
        code: 'kwd',
        label: I18nTranslate.to(language).tr('Kuwaiti Dinar'),
        symbol: 'KWD'
      },
      {
        code: 'kyd',
        label: I18nTranslate.to(language).tr('Cayman Islands Dollar'),
        symbol: 'KYD'
      },
      {
        code: 'kzt',
        label: I18nTranslate.to(language).tr('Tenge'),
        symbol: 'KZT'
      },
      {
        code: 'lak',
        label: I18nTranslate.to(language).tr('Kip'),
        symbol: 'LAK'
      },
      {
        code: 'lbp',
        label: I18nTranslate.to(language).tr('Lebanese Pound'),
        symbol: 'LBP'
      },
      {
        code: 'lkr',
        label: I18nTranslate.to(language).tr('Sri Lanka Rupee'),
        symbol: 'LKR'
      },
      {
        code: 'lrd',
        label: I18nTranslate.to(language).tr('Liberian Dollar'),
        symbol: 'LSD'
      },
      {
        code: 'lsl',
        label: I18nTranslate.to(language).tr('Loti'),
        symbol: 'LSL'
      },
      {
        code: 'lyd',
        label: I18nTranslate.to(language).tr('Libyan Dinar'),
        symbol: 'LYD'
      },
      {
        code: 'mad',
        label: I18nTranslate.to(language).tr('Moroccan Dirham'),
        symbol: 'MAD'
      },
      {
        code: 'mdl',
        label: I18nTranslate.to(language).tr('Moldovan Leu'),
        symbol: 'MDL'
      },
      {
        code: 'mga',
        label: I18nTranslate.to(language).tr('Malagasy Ariary'),
        symbol: 'MGA'
      },
      {
        code: 'mkd',
        label: I18nTranslate.to(language).tr('Denar'),
        symbol: 'MKD'
      },
      {
        code: 'mmk',
        label: I18nTranslate.to(language).tr('Kyat'),
        symbol: 'MMK'
      },
      {
        code: 'mnt',
        label: I18nTranslate.to(language).tr('Tugrik'),
        symbol: 'MNT'
      },
      {
        code: 'mop',
        label: I18nTranslate.to(language).tr('Pataca'),
        symbol: 'MOP'
      },
      {
        code: 'mro',
        label: I18nTranslate.to(language).tr('Ouguiya'),
        symbol: 'MRO'
      },
      {
        code: 'mur',
        label: I18nTranslate.to(language).tr('Mauritius Rupee'),
        symbol: 'MUR'
      },
      {
        code: 'mvr',
        label: I18nTranslate.to(language).tr('Rufiyaa'),
        symbol: 'MVR'
      },
      {
        code: 'mwk',
        label: I18nTranslate.to(language).tr('Malawi Kwacha'),
        symbol: 'MWK'
      },
      {
        code: 'mxn',
        label: I18nTranslate.to(language).tr('Mexican Peso'),
        symbol: 'MX$'
      },
      {
        code: 'myr',
        label: I18nTranslate.to(language).tr('Malaysian Ringgit'),
        symbol: 'MYR'
      },
      {
        code: 'mzn',
        label: I18nTranslate.to(language).tr('Mozambique Metical'),
        symbol: 'MZN'
      },
      {
        code: 'nad',
        label: I18nTranslate.to(language).tr('Namibia Dollar'),
        symbol: 'NAD'
      },
      {
        code: 'ngn',
        label: I18nTranslate.to(language).tr('Naira'),
        symbol: 'NGN'
      },
      {
        code: 'nio',
        label: I18nTranslate.to(language).tr('Cordoba Oro'),
        symbol: 'NIO'
      },
      {
        code: 'nok',
        label: I18nTranslate.to(language).tr('Norwegian Krone'),
        symbol: 'NOK'
      },
      {
        code: 'npr',
        label: I18nTranslate.to(language).tr('Nepalese Rupee'),
        symbol: 'NPR'
      },
      {
        code: 'nzd',
        label: I18nTranslate.to(language).tr('New Zealand Dollar'),
        symbol: 'NZ$'
      },
      {
        code: 'omr',
        label: I18nTranslate.to(language).tr('Rial Omani'),
        symbol: 'OMR'
      },
      {
        code: 'pab',
        label: I18nTranslate.to(language).tr('Balboa'),
        symbol: 'PAB'
      },
      {
        code: 'pen',
        label: I18nTranslate.to(language).tr('Sol'),
        symbol: 'PEN'
      },
      {
        code: 'pgk',
        label: I18nTranslate.to(language).tr('Kina'),
        symbol: 'PGK'
      },
      {
        code: 'php',
        label: I18nTranslate.to(language).tr('Philippine Peso'),
        symbol: 'PHP'
      },
      {
        code: 'pkr',
        label: I18nTranslate.to(language).tr('Pakistan Rupee'),
        symbol: 'PKR'
      },
      {
        code: 'pln',
        label: I18nTranslate.to(language).tr('Zloty'),
        symbol: 'PLN'
      },
      {
        code: 'pyg',
        label: I18nTranslate.to(language).tr('Guarani'),
        symbol: 'PYG'
      },
      {
        code: 'qar',
        label: I18nTranslate.to(language).tr('Qatari Rial'),
        symbol: 'QAR'
      },
      {
        code: 'ron',
        label: I18nTranslate.to(language).tr('Romanian Leu'),
        symbol: 'RON'
      },
      {
        code: 'rsd',
        label: I18nTranslate.to(language).tr('Serbian Dinar'),
        symbol: 'RSD'
      },
      {
        code: 'rub',
        label: I18nTranslate.to(language).tr('Russian Ruble'),
        symbol: 'RUB'
      },
      {
        code: 'rwf',
        label: I18nTranslate.to(language).tr('Rwanda Franc'),
        symbol: 'RWF'
      },
      {
        code: 'sar',
        label: I18nTranslate.to(language).tr('Saudi Riyal'),
        symbol: 'SAR'
      },
      {
        code: 'sbd',
        label: I18nTranslate.to(language).tr('Solomon Islands Dollar'),
        symbol: 'SBD'
      },
      {
        code: 'scr',
        label: I18nTranslate.to(language).tr('Seychelles Rupee'),
        symbol: 'SCR'
      },
      {
        code: 'sdg',
        label: I18nTranslate.to(language).tr('Sudanese Pound'),
        symbol: 'SDG'
      },
      {
        code: 'sek',
        label: I18nTranslate.to(language).tr('Swedish Krona'),
        symbol: 'SEK'
      },
      {
        code: 'sgd',
        label: I18nTranslate.to(language).tr('Singapore Dollar'),
        symbol: 'SGD'
      },
      {
        code: 'shp',
        label: I18nTranslate.to(language).tr('Saint Helena Pound'),
        symbol: 'SHP'
      },
      {
        code: 'sll',
        label: I18nTranslate.to(language).tr('Leone'),
        symbol: 'SLL'
      },
      {
        code: 'sos',
        label: I18nTranslate.to(language).tr('Somali Shilling'),
        symbol: 'SOS'
      },
      {
        code: 'srd',
        label: I18nTranslate.to(language).tr('Surinam Dollar'),
        symbol: 'SRD'
      },
      {
        code: 'ssp',
        label: I18nTranslate.to(language).tr('South Sudanese Pound'),
        symbol: 'SSP'
      },
      {
        code: 'std',
        label: I18nTranslate.to(language).tr('Dobra'),
        symbol: 'STD'
      },
      {
        code: 'svc',
        label: I18nTranslate.to(language).tr('El Salvador Colon'),
        symbol: 'SVC'
      },
      {
        code: 'syp',
        label: I18nTranslate.to(language).tr('Syrian Pound'),
        symbol: 'SYP'
      },
      {
        code: 'szl',
        label: I18nTranslate.to(language).tr('Lilangeni'),
        symbol: 'SZL'
      },
      {
        code: 'thb',
        label: I18nTranslate.to(language).tr('Baht'),
        symbol: 'THB'
      },
      {
        code: 'tjs',
        label: I18nTranslate.to(language).tr('Somoni'),
        symbol: 'TJS'
      },
      {
        code: 'tmt',
        label: I18nTranslate.to(language).tr('Turkmenistan New Manat'),
        symbol: 'TMT'
      },
      {
        code: 'tnd',
        label: I18nTranslate.to(language).tr('Tunisian Dinar'),
        symbol: 'TND'
      },
      {
        code: 'top',
        label: I18nTranslate.to(language).tr('Pa’anga'),
        symbol: 'TOP'
      },
      {
        code: 'try',
        label: I18nTranslate.to(language).tr('Turkish Lira'),
        symbol: 'TRY'
      },
      {
        code: 'ttd',
        label: I18nTranslate.to(language).tr('Trinidad and Tobago Dollar'),
        symbol: 'TTD'
      },
      {
        code: 'twd',
        label: I18nTranslate.to(language).tr('New Taiwan Dollar'),
        symbol: 'NT$'
      },
      {
        code: 'tzs',
        label: I18nTranslate.to(language).tr('Tanzanian Shilling'),
        symbol: 'TZS'
      },
      {
        code: 'uah',
        label: I18nTranslate.to(language).tr('Hryvnia'),
        symbol: 'UAH'
      },
      {
        code: 'ugx',
        label: I18nTranslate.to(language).tr('Uganda Shilling'),
        symbol: 'UGX'
      },
      {
        code: 'usd',
        label: I18nTranslate.to(language).tr('US Dollar'),
        symbol: '$'
      },
      {
        code: 'uyu',
        label: I18nTranslate.to(language).tr('Peso Uruguayo'),
        symbol: 'UYU'
      },
      {
        code: 'uzs',
        label: I18nTranslate.to(language).tr('Uzbekistan Sum'),
        symbol: 'UZS'
      },
      {
        code: 'vef',
        label: I18nTranslate.to(language).tr('Bolívar'),
        symbol: 'VEF'
      },
      {
        code: 'vnd',
        label: I18nTranslate.to(language).tr('Dong'),
        symbol: '₫'
      },
      {
        code: 'vuv',
        label: I18nTranslate.to(language).tr('Vatu'),
        symbol: 'VUV'
      },
      {
        code: 'wst',
        label: I18nTranslate.to(language).tr('Tala'),
        symbol: 'WST'
      },
      {
        code: 'xaf',
        label: I18nTranslate.to(language).tr('CFA Franc BEAC'),
        symbol: 'FCFA'
      },
      {
        code: 'xag',
        label: I18nTranslate.to(language).tr('Silver'),
        symbol: 'XAG'
      },
      {
        code: 'xau',
        label: I18nTranslate.to(language).tr('Gold'),
        symbol: 'XAU'
      },
      {
        code: 'xcd',
        label: I18nTranslate.to(language).tr('East Caribbean Dollar'),
        symbol: 'EC$'
      },
      {
        code: 'xof',
        label: I18nTranslate.to(language).tr('CFA Franc BCEAO'),
        symbol: 'CFA'
      },
      {
        code: 'xpd',
        label: I18nTranslate.to(language).tr('Palladium'),
        symbol: 'XPD'
      },
      {
        code: 'xpf',
        label: I18nTranslate.to(language).tr('CFP Franc'),
        symbol: 'CFPF'
      },
      {
        code: 'xpt',
        label: I18nTranslate.to(language).tr('Platinum'),
        symbol: 'XPT'
      },
      {
        code: 'xsu',
        label: I18nTranslate.to(language).tr('Sucre'),
        symbol: 'XSU'
      },
      {
        code: 'yer',
        label: I18nTranslate.to(language).tr('Yemeni Rial'),
        symbol: 'YER'
      },
      {
        code: 'zar',
        label: I18nTranslate.to(language).tr('Rand'),
        symbol: 'XAR'
      },
      {
        code: 'zmw',
        label: I18nTranslate.to(language).tr('Zambian Kwacha'),
        symbol: 'ZMW'
      },
      {
        code: 'zwl',
        label: I18nTranslate.to(language).tr('Zimbabwe Dollar'),
        symbol: 'ZWL'
      }
    ]
  }
}
