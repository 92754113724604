import { NumberProperty, PercentageProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class FeedingRateModel extends Model {
  constructor(f = {}) {
    super(f)
    this.averageWeight = new NumberProperty({
      label: Localization.tr('Avg weight'),
      value: f.averageWeight,
      unit: Localization.tr('g'),
      step: 0.01
    })

    this.feedRatio = new PercentageProperty({
      label: Localization.tr('Percentage to feed'),
      value: f.feedRatio
    })
  }

  export() {
    return {
      averageWeight: this.averageWeight.formattedValue,
      feedRatio: this.feedRatio.formattedValue
    }
  }
}
