import { BooleanProperty, TextProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { Strings } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

export class WorkflowDefinitionModel extends Model {
  constructor(w = {}) {
    super(w)
    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: w.name,
      formatValue: value => Strings.capitalize(value)
    })

    this.description = new TextProperty({
      label: Localization.tr('Description'),
      value: w.description
    })

    this.enabledByDefault = new BooleanProperty({
      value: w.enabledByDefault
    })
  }
}
