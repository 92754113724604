import {
  CalendarDateProperty,
  CollectionProperty,
  DateTimeProperty,
  NumberProperty,
  ObjectProperty,
  PercentageProperty,
  TextProperty,
  TimeProperty
} from '../models/properties.js'

import { ApplicationModel } from '../applications/application-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class MealModel extends Model {
  constructor(m = {}) {
    super(m)
    this.application = new ObjectProperty({
      label: Localization.tr('Species'),
      value: m.application,
      type: ApplicationModel,
      formatValue: value => value.name.formattedValue
    })

    this.feedChecks = new CollectionProperty({
      label: Localization.tr('Feed checks'),
      value: m.feedChecks
    })

    this.index = new TextProperty({
      label: Localization.tr('#'),
      value: m.index
    })

    this.populationName = new TextProperty({
      label: Localization.tr('Population Name'),
      value: m.populationName
    })

    this.populationId = new TextProperty({
      label: Localization.tr('Population ID'),
      value: m.populationId
    })

    this.dateTime = new DateTimeProperty({
      label: Localization.tr('Date & time'),
      value: m.dateTime
    })

    this.date = new CalendarDateProperty({
      label: Localization.tr('Date'),
      value: m.dateTime
    })

    this.time = new TimeProperty({
      label: Localization.tr('Time'),
      value: m.dateTime
    })

    this.checkTime = new TimeProperty({
      label: Localization.tr('Check time'),
      value: m.checkTime
    })

    this.amountFed = new NumberProperty({
      label: Localization.tr('Amount fed'),
      value: m.amountFed,
      unit: Localization.tr('kg'),
      step: 0.01,
      min: 0
    })

    this.deadAnimals = new NumberProperty({
      label: Localization.tr('Dead pieces'),
      value: m.deadAnimals,
      unit: Localization.tr('pieces'),
      min: 0,
      step: 1
    })

    this.feedRemaining = new PercentageProperty({
      label: Localization.tr('Feed left'),
      value: m.feedRemaining
    })

    this.feedCode = new TextProperty({
      label: Localization.tr('Feed code'),
      value: m.feedCode
    })
  }

  export() {
    return Object.assign(
      {},
      {
        date: this.dateTime.formattedValue,
        amountFed: this.amountFed.formattedValue,
        feedRemaining: this.feedRemaining.formattedValue,
        feedCode: this.feedCode.formattedValue,
        deadAnimals: this.deadAnimals.formattedValue
      }
    )
  }
}
