import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { TextProperty } from '../models/properties.js'

export class WorkflowContextModel extends Model {
  constructor(c = {}) {
    super(c)
    this.sessionId = new TextProperty({
      label: Localization.tr('Session ID'),
      value: c.sessionId
    })

    this.workflowId = new TextProperty({
      label: Localization.tr('Workflow ID'),
      value: c.workflowId
    })

    this.sessionType = new TextProperty({
      label: Localization.tr('Session type'),
      value: c.sessionType
    })

    this.sampleUnitCount = new TextProperty({
      label: Localization.tr('Sample unit count'),
      value: c.sampleUnitCount
    })
  }
}
