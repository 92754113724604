import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  PercentageProperty,
  TextProperty
} from '../models/properties.js'

import { CUSTOMER_TYPES } from '../customers/customer-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Log } from '../../libs/log.js'
import { Model } from '../models/model.js'
import { ORGANIZATION_TYPES } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { SupportedLanguages } from '../../libs/localization/supported-languages.js'

export const ORGANIZATION_TYPES_LABELS = [
  {
    code: ORGANIZATION_TYPES.AQUACULTURE_PRODUCER,
    label: Localization.tr('Aquaculture producer')
  },
  {
    code: ORGANIZATION_TYPES.HATCHERY,
    label: Localization.tr('Hatchery')
  },
  {
    code: ORGANIZATION_TYPES.FARM,
    label: Localization.tr('Farm')
  },
  {
    code: ORGANIZATION_TYPES.RESEARCH_CENTER,
    label: Localization.tr('Research center')
  },
  {
    code: ORGANIZATION_TYPES.UNIVERSITY,
    label: Localization.tr('University')
  },
  {
    code: ORGANIZATION_TYPES.AQUARIUM,
    label: Localization.tr('Aquarium')
  },
  {
    code: ORGANIZATION_TYPES.DISTRIBUTOR,
    label: Localization.tr('Distributor')
  },
  {
    code: ORGANIZATION_TYPES.CONSULTANT,
    label: Localization.tr('Consultant')
  },
  {
    code: ORGANIZATION_TYPES.REPRESENTATIVE,
    label: Localization.tr('Representative')
  },
  {
    code: ORGANIZATION_TYPES.OTHER,
    label: Localization.tr('Other')
  },
  {
    code: ORGANIZATION_TYPES.XPERTSEA_REPRESENTATIVE,
    label: Localization.tr('XpertSea Representative')
  },
  {
    code: ORGANIZATION_TYPES.GOVERNMENT,
    label: Localization.tr('Government')
  },
  {
    code: ORGANIZATION_TYPES.PROCESSING_PLANT,
    label: Localization.tr('Processing Plant')
  }
]

export class OrganizationModel extends Model {
  constructor(o = {}) {
    super(o)
    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: o.name
    })

    this.createdDate = new DateTimeProperty({
      label: Localization.tr('Created'),
      value: o.createdDate
    })

    this.isLocked = new BooleanProperty({
      label: Localization.tr('Status'),
      value: o.isLocked,
      formatValue: value => {
        return value ? Localization.tr('Locked') : Localization.tr('Active')
      }
    })

    this.type = new TextProperty({
      label: Localization.tr('Organization type'),
      value: o.type,
      errorMessage: Localization.tr('Missing organization type.'),
      formatValue: value => {
        const type = ORGANIZATION_TYPES_LABELS.find(t => t.code === value)
        return type ? type.label : Localization.tr('Aquaculture producer')
      }
    })

    this.country = new TextProperty({
      label: Localization.tr('Country'),
      value: o.country,
      errorMessage: Localization.tr('Missing country.'),
      formatValue: value => {
        if (!value) {
          return ''
        }

        const country = Localization.getCountryFromCode(value)

        if (!country) {
          Log.warn(`Invalid country ${value} in organization ${o.id}`)
          return ''
        }

        return country.label
      }
    })

    this.language = new TextProperty({
      label: Localization.tr('Default language'),
      value: o.language,
      errorMessage: Localization.tr('Missing default language.'),
      formatValue: value => {
        if (!value) {
          return ''
        }

        const language = SupportedLanguages.fromCode(value)

        if (!language) {
          Log.warn(`Invalid language ${value} in organization ${o.id}`)
          return ''
        }

        return language.label
      }
    })

    this.currency = new TextProperty({
      label: Localization.tr('Currency'),
      value: o.currency,
      errorMessage: Localization.tr('Missing currency.'),
      formatValue: value => {
        if (!value) {
          return ''
        }

        const currency = Localization.getCurrencyFromCode(value.toLowerCase())

        if (!currency) {
          Log.warn(`Invalid currency ${value} in organization ${o.id}`)
          return ''
        }

        return currency.label
      }
    })

    this.timezone = new TextProperty({
      label: Localization.tr('Organization time zone'),
      value: o.timezone,
      errorMessage: Localization.tr('Missing organization time zone.'),
      formatValue: value => {
        if (!value) {
          return ''
        }

        const timezone = Localization.getTimezoneFromCode(value)

        if (!timezone) {
          Log.warn(`Invalid timezone ${value} in organization ${o.id}`)
          return ''
        }

        return timezone.label
      }
    })

    this.customerName = new TextProperty({
      label: Localization.tr('Customer'),
      value: o.customerName
    })

    this.customerId = new TextProperty({
      value: o.customerId
    })

    this.customerType = new TextProperty({
      value: o.customerType
    })

    this.ascEnabled = new BooleanProperty({
      label: Localization.tr('ASC certification'),
      value: o.ascEnabled,
      formatValue: value => {
        return value ? Localization.tr('On') : Localization.tr('Off')
      }
    })

    this.feedRxEnabled = new BooleanProperty({
      label: Localization.tr('Feed recommendations'),
      value: o.feedRxEnabled,
      formatValue: value => {
        return value ? Localization.tr('On') : Localization.tr('Off')
      }
    })

    this.feedRegimeId = new TextProperty({
      label: Localization.tr('Feed regime'),
      value: o.feedRegimeId
    })

    this.monitoringPondFeatureEnabled = new BooleanProperty({
      label: Localization.tr('Mobile monitoring feature'),
      value: o.monitoringPondFeatureEnabled,
      formatValue: value => {
        return value ? Localization.tr('On') : Localization.tr('Off')
      }
    })

    this.tradingFeatureEnabled = new BooleanProperty({
      label: Localization.tr('Trading feature'),
      value: o.tradingFeatureEnabled,
      formatValue: value => {
        return value ? Localization.tr('On') : Localization.tr('Off')
      }
    })

    this.ascCertificationNumber = new TextProperty({
      label: Localization.tr('ASC (company) certification number'),
      value: o.ascCertificationNumber
    })

    this.latitude = new NumberProperty({
      label: Localization.tr('Latitude'),
      value: o.latitude,
      min: -90,
      max: 90,
      step: 0.00001,
      unit: '°'
    })

    this.longitude = new NumberProperty({
      label: Localization.tr('Longitude'),
      value: o.longitude,
      min: -180,
      max: 180,
      step: 0.00001,
      unit: '°'
    })

    this.reviewed = new BooleanProperty({
      label: Localization.tr('Reviewed by XpertSea'),
      value: o.reviewed,
      formatValue: value => {
        return value ? Localization.tr('Yes') : Localization.tr('No')
      }
    })

    this.phone = new TextProperty({
      label: Localization.tr('Phone'),
      value: o.phone
    })

    this.phoneExtension = new TextProperty({
      label: Localization.tr('Extension'),
      value: o.phoneExtension,
      errorMessage: Localization.tr('Maximum 6 digits.'),
      description: Localization.tr('if needed, enter the extension number')
    })

    this.legalName = new TextProperty({
      label: Localization.tr('Legal name'),
      value: o.legalName
    })

    this.mainContactName = new TextProperty({
      label: Localization.tr('Main contact'),
      value: o.mainContactName
    })

    this.email = new TextProperty({
      label: Localization.tr('Email address'),
      value: o.email
    })

    this.taxId = new TextProperty({
      label: Localization.tr('RUC'),
      value: o.taxId,
      description: `${Localization.tr('Ex:')} 0000123456789`
    })

    this.totalMargin = new PercentageProperty({
      label: Localization.tr('Total margin'),
      value: o.totalMargin
    })

    this.visible = new BooleanProperty({
      label: Localization.tr('Visibility'),
      value: o.visible,
      formatValue: value => {
        return value ? Localization.tr('Yes') : Localization.tr('No')
      }
    })

    this.streetAddress = new TextProperty({
      label: Localization.tr('Street address'),
      value: o.streetAddress
    })

    this.city = new TextProperty({
      label: Localization.tr('City'),
      value: o.city
    })

    this.region = new TextProperty({
      label: Localization.tr('Province / Territory'),
      value: o.region
    })

    this.postalCode = new TextProperty({
      label: Localization.tr('Postal code'),
      value: o.postalCode
    })
  }

  hasAccessToEnterpriseFeature() {
    return this.customerType.value === CUSTOMER_TYPES.ENTERPRISE
  }
}
