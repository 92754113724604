import { NumberProperty, Property } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class SessionCountModel extends Model {
  constructor(w = {}) {
    super(w)
    this.index = new Property({
      label: Localization.tr('#'),
      value: w.index,
      formatValue: value => `${Localization.toLocaleFixedString(value + 1)}`
    })

    this.animalAmountCounted = new NumberProperty({
      label: Localization.tr('Total count'),
      value: w.animalAmountCounted
    })
  }
}
