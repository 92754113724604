import { Log } from './log.js'

export class Platform {
  static dispatchEvent(event) {
    window.dispatchEvent(event)
  }

  static getLocation() {
    return window.location
  }

  static navigateTo({ path }) {
    window.history.pushState({}, null, path)
    window.dispatchEvent(new CustomEvent('location-changed'))
  }

  static redirectTo({ path }) {
    if (path.startsWith('/react')) {
      return this.setLocation({ url: path })
    }

    window.history.replaceState({}, null, path)
    window.dispatchEvent(new CustomEvent('location-changed'))
  }

  static setLocation({ url }) {
    window.location = url
  }

  static locationAssign({ path }) {
    window.location.assign(path)
  }

  static refresh() {
    window.location.reload(true)
  }

  static get isServiceWorkerSupported() {
    return 'serviceWorker' in navigator
  }

  static get isIos() {
    return Boolean(navigator.platform.match(/iPhone|iPod|iPad/))
  }

  static get isMac() {
    return Boolean(navigator.platform.match(/MacIntel/))
  }

  static async registerServiceWorker({ serviceWorkerPath = '/service-worker.js', scope = '/', onUpdateFound } = {}) {
    const serviceWorkerRegistration = await navigator.serviceWorker.register(serviceWorkerPath, { scope })
    if (onUpdateFound) {
      serviceWorkerRegistration.addEventListener('updatefound', () =>
        onUpdateFound({ newServiceWorker: serviceWorkerRegistration.installing })
      )
    }

    return serviceWorkerRegistration
  }

  static async clearCaches({ exceptions = [] }) {
    const cacheNames = await caches.keys()
    return Promise.all(
      cacheNames
        .filter(cacheName => exceptions.every(exception => !cacheName.includes(exception)))
        .map(cacheName => caches.delete(cacheName))
    )
  }

  static async lazyLoadElement({ element, beforeLoad, load, afterLoad }) {
    const elementTagName = element.tagName.toLowerCase()
    const isAlreadyLoaded = Boolean(customElements.get(elementTagName))
    if (!isAlreadyLoaded) {
      if (beforeLoad) {
        await beforeLoad()
      }

      try {
        await load({ elementTagName })
      } catch (error) {
        Log.info(error)
        return
      }
    }

    if (afterLoad) {
      await afterLoad()
    }
  }
}
