import { FeedCheckModel } from './feed-check-model.js'
import { MealModel } from './meal-model.js'
import { ModelConverter } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { StateMapper } from '../models/state-mapper.js'

export class MealModelConverter extends ModelConverter {
  convert(m) {
    let feedChecks = null
    if (m.feedChecks) {
      feedChecks = m.feedChecks.map(fc => new FeedCheckModel(fc))
    }

    return new MealModel(
      Object.assign({}, m, {
        application: StateMapper.findApplication({
          id: m.applicationId
        }),
        feedChecks
      })
    )
  }
}
