import { DeepwaterSessionPictureMapper } from './deepwater-session-picture-mapper.js'
import { PictureModel } from '../../pictures/picture-model.js'
import { DeepwaterSessionMapper as ScubaDeepwaterSessionMapper } from '../../../../node_modules/@xpertsea/scuba/dist/esm/repositories/sessions/deepwater/deepwater-session-mapper.js'
import { SessionCountModel } from '../session-count-model.js'
import { SessionModel } from '../session-model.js'
import { SessionPayloadModel } from '../session-payload-model.js'
import { StateMapper } from '../../models/state-mapper.js'
import { WarningModel } from '../../warnings/warning-model.js'
import { WorkflowContextModel } from '../../workflow-context/workflow-context-model.js'

export class DeepwaterSessionMapper {
  static toModel(m, index, applications) {
    // FIXME - remove this after migrate Session to Scuba
    const dto = ScubaDeepwaterSessionMapper.toDto(m, index)

    return this.fromDto(dto)
  }

  static fromDto(dto) {
    const { picture, payload, workflowContext, counts, warnings, application } = this.toDependenciesModel(dto)

    return new SessionModel(
      Object.assign({}, dto, {
        application,
        picture,
        payload,
        workflowContext,
        counts,
        warnings
      })
    )
  }

  static toDependenciesModel(dto, applications) {
    let picture = null
    if (dto.picture) {
      dto.picture.metas = DeepwaterSessionPictureMapper.convertPictureMetas(dto.picture.metas)
      picture = new PictureModel(dto.picture)
    }

    let payload = null
    if (dto.payload) {
      payload = new SessionPayloadModel(dto.payload)
    }

    let workflowContext = null
    if (dto.workflowContext) {
      workflowContext = new WorkflowContextModel(dto.workflowContext)
    }

    let counts = []
    if (dto.counts) {
      counts = dto.counts.map(c => new SessionCountModel(c))
    }

    let warnings = []
    if (dto.warnings) {
      warnings = dto.warnings.map(w => new WarningModel(w))
    }

    const application = DeepwaterSessionMapper._findApplication(dto.applicationId, applications)

    return { picture, payload, workflowContext, counts, warnings, application }
  }

  static _findApplication(id, applications) {
    if (applications && applications.length > 0) {
      return applications.find(a => a.id.value === id)
    }

    return StateMapper.findApplication({ id })
  }

  static fromModelField(field) {
    return ScubaDeepwaterSessionMapper.fromDtoField(field)
  }
}
