import { Dates } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Localization } from '../../../libs/localization/localization.js'
import { Property } from './property.js'

export class DateTimeProperty extends Property {
  constructor({ value, label, formatValue = null, formattedDefault = '' } = {}) {
    super({
      value: Dates.from({
        source: value
      }),
      label,
      formatValue:
        formatValue === null
          ? v => {
              return Dates.format({ date: v, as: 'dateTime', tz: Localization.getCurrentTimezone() })
            }
          : formatValue
    })

    if (!this.formattedValue) {
      this.formattedValue = formattedDefault
    }
  }
}
