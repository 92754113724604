import { I18nTranslate, LanguageBuilder } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

import { Countries } from './countries.js'
import { Currencies } from './currencies.js'
import { DateTime } from '../../../node_modules/luxon/src/luxon.js'
import { Persistence } from '../../data/persistence.js'
import { Platform } from '../platform.js'
import { SupportedLanguages } from './supported-languages.js'
import { Timezones } from './timezones.js'

export const PERSISTED_LANGUAGE_KEY = 'settings::language'
export const PERSISTED_TIMEZONE_KEY = 'settings::timezone'
export const PERSISTED_ALREADY_ASKED_UPDATE_LANGUAGE = 'notifications::update-language'

function determineLanguageCode() {
  let languageCode = Persistence.find(PERSISTED_LANGUAGE_KEY)

  if (!languageCode) {
    languageCode = Persistence.save(
      PERSISTED_LANGUAGE_KEY,
      LanguageBuilder.findClosestCode({ code: navigator.language, list: SupportedLanguages.list })
    )
  }

  return languageCode
}

function determineTimezoneCode() {
  let timezoneCode = Persistence.find(PERSISTED_TIMEZONE_KEY)

  if (!timezoneCode) {
    timezoneCode = Persistence.save(PERSISTED_TIMEZONE_KEY, DateTime.local().zone.name)
  }

  return timezoneCode
}

const currentTimezoneCode = determineTimezoneCode()
const timezones = new Timezones()
let currentTimezone = timezones.list.find(timezone => timezone.code === currentTimezoneCode)

const currentLanguageCode = determineLanguageCode()
const currentLanguage = LanguageBuilder.build({ code: currentLanguageCode, list: SupportedLanguages.list })
const countries = new Countries({ language: currentLanguage })
const currencies = new Currencies({ language: currentLanguage })

export class Localization {
  static get currentLanguage() {
    return currentLanguage.code
  }

  static changeLanguage(languageCode) {
    const language = SupportedLanguages.fromCode(languageCode)
    if (!language) {
      throw new Error(`Unknown language ${languageCode}`)
    }

    Persistence.save(PERSISTED_LANGUAGE_KEY, languageCode)
    Platform.refresh()
  }

  static hasAlreadyAskedToUpdateLanguage() {
    return Persistence.find(PERSISTED_ALREADY_ASKED_UPDATE_LANGUAGE, false)
  }

  static dontAskToUpdateLanguageAgain() {
    Persistence.save(PERSISTED_ALREADY_ASKED_UPDATE_LANGUAGE, true)
  }

  static tr(message, replacements = {}) {
    return I18nTranslate.to(currentLanguage).tr(message, replacements)
  }

  static buildDictionary(translations = {}) {
    return I18nTranslate.to(currentLanguage).buildDictionary(translations)
  }

  static toLocaleString(value) {
    return I18nTranslate.to(currentLanguage).localeString(value)
  }

  static toLocaleFixedString(value, decimalAmount) {
    return I18nTranslate.to(currentLanguage).localeFixedString(value, decimalAmount)
  }

  static getCurrentTimezone() {
    return currentTimezone.code
  }

  static getTimezones() {
    return timezones.list
  }

  static getTimezoneFromCode(timezoneCode) {
    return timezones.list.find(timezone => timezone.code === timezoneCode)
  }

  static changeTimezone(timezoneCode) {
    const timezone = this.getTimezoneFromCode(timezoneCode)
    if (!timezone) {
      throw new Error(`Unknown timezone ${timezoneCode}`)
    }

    Persistence.save(PERSISTED_TIMEZONE_KEY, timezoneCode)
    currentTimezone = timezone
    return timezone
  }

  static getCountries() {
    return countries.list
  }

  static getCountryFromCode(countryCode) {
    return countries.list.find(country => country.code === countryCode)
  }

  static getCurrencyFromCode(currencyCode) {
    return currencies.list.find(currency => currency.code === currencyCode)
  }

  static getCurrencies() {
    return currencies.list
  }
}
