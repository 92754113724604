import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { TextProperty } from '../models/properties.js'

export class SessionPayloadModel extends Model {
  constructor(d = {}) {
    super(d)

    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: d.name
    })

    this.url = new TextProperty({
      label: Localization.tr('Payload'),
      value: d.url
    })
  }
}
