/* eslint-disable no-console */

import {
  captureException,
  captureMessage,
  configureScope,
  init,
  withScope
} from '../../node_modules/@sentry/browser/esm/index.js'

import { AppVersion } from '../../version.js'
import { Configuration } from '../../configuration.js'
import { isWarning } from '../../node_modules/@xpertsea/scuba/dist/esm/index.js'

const isLoggingActivated = Boolean(Configuration.analytics.sentryDsn)
if (isLoggingActivated) {
  init({
    dsn: Configuration.analytics.sentryDsn,
    environment: Configuration.environment,
    release: `app@${AppVersion}`
  })
}

export class Log {
  static updateContext({ user, organization, customer }) {
    if (!isLoggingActivated) {
      return
    }

    let scopeUser = null
    if (user) {
      scopeUser = Object.assign({}, scopeUser, {
        email: user.email.value,
        id: user.id.value,
        name: user.displayName.value
      })
    }

    if (organization) {
      scopeUser = Object.assign({}, scopeUser, {
        organizationId: organization.id.value,
        organizationName: organization.name.value
      })
    }

    if (customer) {
      scopeUser = Object.assign({}, scopeUser, {
        customerId: customer.id.value,
        customerName: customer.name.value,
        customerType: customer.type.value
      })
    } else if (organization) {
      scopeUser = Object.assign({}, scopeUser, {
        customerId: organization.customerId.value,
        customerName: organization.customerName.value,
        customerType: organization.customerType.value
      })
    }

    configureScope(scope => scope.setUser(scopeUser))
  }

  static info(message) {
    console.info(message)

    if (isLoggingActivated) {
      withScope(scope => {
        scope.setLevel('info')

        captureMessage(message)
      })
    }
  }

  static warn(message) {
    console.warn(message)

    if (isLoggingActivated) {
      withScope(scope => {
        scope.setLevel('warning')

        captureMessage(message)
      })
    }
  }

  static error(error) {
    const isClientError = isWarning(error)
    console.error(error)

    if (isLoggingActivated) {
      if (isClientError) {
        withScope(scope => {
          scope.setLevel('warning')

          captureException(error)
        })
      } else {
        captureException(error)
      }
    }
  }
}
