import { CollectionProperty, DateTimeProperty, TextProperty } from '../models/properties.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class PictureModel extends Model {
  constructor(w = {}) {
    super(w)

    const dateTime = new DateTimeProperty({
      label: Localization.tr('Date & time'),
      value: w.dateTime
    })

    this.url = new TextProperty({
      label: Localization.tr('Picture'),
      value: w.url
    })

    const metas = w.metas || []
    metas.push({
      value: dateTime.formattedValue
    })

    this.metas = new CollectionProperty({
      label: Localization.tr('Picture metadata'),
      value: metas
    })
  }
}
