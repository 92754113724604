import { NumberProperty, TextProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class WeightGroupModel extends Model {
  constructor(w = {}) {
    super(w)
    this.indexInMonitoring = new TextProperty({
      label: Localization.tr('#'),
      value: w.indexInMonitoring
    })

    this.animalAmountCounted = new NumberProperty({
      label: Localization.tr('Count'),
      value: w.animalAmountCounted,
      unit: Localization.tr('pieces')
    })

    this.averageWeight = new NumberProperty({
      label: Localization.tr('Avg weight'),
      value: w.averageWeight,
      unit: Localization.tr('g'),
      step: 0.01
    })

    this.totalWeight = new NumberProperty({
      label: Localization.tr('Total weight'),
      value: w.totalWeight,
      unit: Localization.tr('g'),
      step: 0.01
    })
  }
}
