import { Localization } from '../../../libs/localization/localization.js'
import { Property } from './property.js'
export class BooleanProperty extends Property {
  static getDefaultFormatValue(value) {
    if (value == null) {
      return null
    }

    return value ? Localization.tr('Yes') : Localization.tr('No')
  }

  constructor({ value, label, formatValue, allowEmpty = false } = {}) {
    super({
      value: value == null && allowEmpty ? null : Boolean(value),
      label,
      formatValue: formatValue || BooleanProperty.getDefaultFormatValue
    })

    this.hasValue = this.value != null
  }
}
