import { ApplicationModel } from './application-model.js'
import { DeepwaterAnimalAmountPerWeightCostsMapper } from './deepwater/deepwater-animal-amount-per-weight-costs-mapper.js'
import { DeepwaterHarvestBreakdownMapper } from '../harvests/deepwater/deepwater-harvest-breakdown-mapper.js'
import { FeedingRateModel } from '../populations/feeding-rate-model.js'
import { Log } from '../../libs/log.js'
import { ModelConverter } from '@xpertsea/scuba/dist/esm/index.js'
import { StateMapper } from '../models/state-mapper.js'
import { WorkflowDefinitionModel } from '../workflow-definitions/workflow-definition-model.js'

export class ApplicationModelConverter extends ModelConverter {
  convert(a) {
    if (a.organizationId) {
      try {
        const organization = StateMapper.findOrganization({
          id: a.organizationId
        })
        a.costCurrency = organization.currency.value || a.costCurrency
      } catch (e) {
        Log.info(`Organization ${a.organizationId} not found in State`)
      }
    }

    let animalAmountPerWeightCosts = null
    if (a.animalAmountPerWeightCosts) {
      animalAmountPerWeightCosts = DeepwaterAnimalAmountPerWeightCostsMapper.convert(
        a.animalAmountPerWeightCosts,
        a.costCurrency
      )
    }

    let harvestBreakdown = []
    if (a.harvestBreakdown) {
      harvestBreakdown = DeepwaterHarvestBreakdownMapper.convert(a.harvestBreakdown)
    }

    let workflowDefinitions = []
    if (a.workflowDefinitions) {
      workflowDefinitions = a.workflowDefinitions.map(wd => new WorkflowDefinitionModel(wd))
    }

    let feedingRates = []
    if (a.feedingRates) {
      feedingRates = a.feedingRates.map(fr => new FeedingRateModel(fr))
    }

    return new ApplicationModel(
      Object.assign({}, a, {
        animalAmountPerWeightCosts,
        harvestBreakdown,
        workflowDefinitions,
        feedingRates
      })
    )
  }
}
