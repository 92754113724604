import {
  CollectionProperty,
  CurrencyProperty,
  NumberProperty,
  PercentageProperty,
  TextProperty
} from '../../data/models/properties.js'

import { Functional } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Localization } from '../../libs/localization/localization.js'
import { dedupingMixin } from '../../../node_modules/@polymer/polymer/lib/utils/mixin.js'

/**
 * @polymer
 * @mixinFunction
 */

export const ApplicationSettingsMixin = dedupingMixin(Base => {
  /**
   * @polymer
   * @mixinClass
   */
  return class ApplicationSettingsMixinClass extends Base {
    constructor(a = {}) {
      super(a)
      this.dailyMortalityRate = new PercentageProperty({
        label: Localization.tr('Daily mortality rate'),
        value: a.dailyMortalityRate
      })

      this.minimumSurvivalRate = new PercentageProperty({
        label: Localization.tr('Minimum survival'),
        value: a.minimumSurvivalRate
      })

      this.feedingRates = new CollectionProperty({
        label: Localization.tr('Feeding rates'),
        value: a.feedingRates
      })

      this.animalAmountPerWeightCosts = new CollectionProperty({
        label: Localization.tr('Market prices'),
        value: a.animalAmountPerWeightCosts
      })

      this.costCurrency = new TextProperty({
        label: Localization.tr('Currency'),
        value: a.costCurrency
      })

      this.feedCostPerWeight = new CurrencyProperty({
        label: Localization.tr('Feed cost'),
        value: a.feedCostPerWeight,
        currency: a.costCurrency,
        unit: Localization.tr('kg')
      })

      this.maintenanceCostPerPeriod = new CurrencyProperty({
        label: Localization.tr('Maintenance costs'),
        value: a.maintenanceCostPerPeriod,
        currency: a.costCurrency,
        unit: Localization.tr('week')
      })

      this.othersCosts = new CurrencyProperty({
        label: Localization.tr('Other costs (fixed)'),
        value: a.othersCosts,
        currency: a.costCurrency
      })

      this.stockingMortality = new PercentageProperty({
        label: Localization.tr('Mortality after stocking'),
        value: a.stockingMortality
      })

      this.fcrCorrection = new PercentageProperty({
        label: Localization.tr('FCR correction'),
        value: a.fcrCorrection,
        max: 9999
      })

      this.feedingCorrection = new PercentageProperty({
        label: Localization.tr('Feeding correction'),
        value: a.feedingCorrection,
        max: 9999
      })

      this.maxFeedingRate = new NumberProperty({
        label: Localization.tr('Max feed rate'),
        value: a.maxFeedingRate,
        unit: Localization.tr('kg/m2'),
        step: 0.1
      })

      this.expectedAvgTemperature = new NumberProperty({
        label: Localization.tr('Avg temperature'),
        value: a.expectedAvgTemperature,
        unit: Localization.tr('°C'),
        step: 0.1
      })

      this.carryingCapacity = new NumberProperty({
        label: Localization.tr('Carrying capacity'),
        value: a.carryingCapacity,
        unit: Localization.tr('kg/m2')
      })
    }

    serializeUpdatableSettings() {
      let animalAmountPerWeightCosts = []
      if (this.animalAmountPerWeightCosts.value) {
        animalAmountPerWeightCosts = this.animalAmountPerWeightCosts.value.map(apw => apw.serialize())
      }

      let feedingRates = []
      if (this.feedingRates.value) {
        feedingRates = this.feedingRates.value.map(fr => fr.serialize())
      }

      return Functional.deepCopy({
        dailyMortalityRate: this.dailyMortalityRate.value,
        minimumSurvivalRate: this.minimumSurvivalRate.value,
        feedingRates,
        animalAmountPerWeightCosts,
        costCurrency: this.costCurrency.value,
        feedCostPerWeight: this.feedCostPerWeight.value,
        maintenanceCostPerPeriod: this.maintenanceCostPerPeriod.value,
        othersCosts: this.othersCosts.value
      })
    }
  }
})
