import { Localization } from '../../../libs/localization/localization.js'

export class Property {
  static getFormatValue(value, formatValue, nullableValueHandler) {
    if (value != null || nullableValueHandler == null) {
      return formatValue && value != null ? formatValue(value) : Localization.toLocaleString(value)
    }

    return nullableValueHandler()
  }

  constructor({ value, label, formatValue, nullableValueHandler, errorMessage } = {}) {
    this.value = value != null ? value : null
    this.label = label || ''
    this.errorMessage = errorMessage || Localization.tr('Invalid value')
    this.formattedValue = Property.getFormatValue(value, formatValue, nullableValueHandler)
  }
}
