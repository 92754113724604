export class StateMapper {
  static findApplication({ id }) {
    return (window.GlobalMediator.currentState.organizationApplications || []).find(a => a.id.value === id)
  }

  static findOrganization({ id }) {
    const currentOrg = window.GlobalMediator.currentState.organization || null

    if (currentOrg.id && currentOrg.id.value !== id) {
      throw Error('Invalid organization id')
    }

    return currentOrg
  }
}
