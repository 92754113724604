import { NumberProperty, ObjectProperty, TextProperty } from '../models/properties.js'
import {
  CONTAINER_CATEGORIES as SCUBA_CONTAINER_CATEGORIES,
  POND_FLOORS as SCUBA_POND_FLOORS,
  POND_TYPES as SCUBA_POND_TYPES,
  TANK_LOCATIONS as SCUBA_TANK_LOCATIONS,
  TANK_MATERIALS as SCUBA_TANK_MATERIALS,
  TANK_TYPES as SCUBA_TANK_TYPES,
  WATER_TYPES as SCUBA_WATER_TYPES
} from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { OrganizationModel } from '../organizations/organization-model.js'
import { TranslatableDictionary } from '../models/translatables-dictionnary.js'

export const CONTAINER_CATEGORIES = new TranslatableDictionary({
  TANK: {
    value: SCUBA_CONTAINER_CATEGORIES.TANK,
    label: Localization.tr('Tank'),
    unit: Localization.tr('L')
  },
  POND: {
    value: SCUBA_CONTAINER_CATEGORIES.POND,
    label: Localization.tr('Pond'),
    unit: Localization.tr('m²')
  }
})

export const POND_TYPES = new TranslatableDictionary({
  NURSERY: {
    value: SCUBA_POND_TYPES.NURSERY,
    label: Localization.tr('Nursery')
  },
  PRE_FATTENING: {
    value: SCUBA_POND_TYPES.PRE_FATTENING,
    label: Localization.tr('Pre-fattening')
  },
  FATTENING: {
    value: SCUBA_POND_TYPES.FATTENING,
    label: Localization.tr('Fattening')
  }
})

export const TANK_TYPES = new TranslatableDictionary({
  NURSERY: {
    value: SCUBA_TANK_TYPES.NURSERY,
    label: Localization.tr('Nursery')
  },
  SPAWNING: {
    value: SCUBA_TANK_TYPES.SPAWNING,
    label: Localization.tr('Spawning')
  },
  LARVAL_REARING: {
    value: SCUBA_TANK_TYPES.LARVAL_REARING,
    label: Localization.tr('Larval rearing')
  },
  MATURATION: {
    value: SCUBA_TANK_TYPES.MATURATION,
    label: Localization.tr('Maturation')
  }
})

export const TANK_LOCATIONS = new TranslatableDictionary({
  INDOOR: {
    value: SCUBA_TANK_LOCATIONS.INDOOR,
    label: Localization.tr('Indoor')
  },
  OUTDOOR_COVERED: {
    value: SCUBA_TANK_LOCATIONS.OUTDOOR_COVERED,
    label: Localization.tr('Outdoor (covered)')
  },
  OUTDOOR_UNCOVERED: {
    value: SCUBA_TANK_LOCATIONS.OUTDOOR_UNCOVERED,
    label: Localization.tr('Outdoor (uncovered)')
  }
})

export const POND_FLOORS = new TranslatableDictionary({
  SANDY: {
    value: SCUBA_POND_FLOORS.SANDY,
    label: Localization.tr('Sandy')
  },
  SANDY_LOAMY: {
    value: SCUBA_POND_FLOORS.SANDY_LOAMY,
    label: Localization.tr('Sandy loamy')
  },
  LOAMY: {
    value: SCUBA_POND_FLOORS.LOAMY,
    label: Localization.tr('Loamy')
  },
  CLAYEY: {
    value: SCUBA_POND_FLOORS.CLAYEY,
    label: Localization.tr('Clayey')
  },
  BLACK: {
    value: SCUBA_POND_FLOORS.BLACK,
    label: Localization.tr('Black')
  },
  RED: {
    value: SCUBA_POND_FLOORS.RED,
    label: Localization.tr('Red')
  },
  SYNTHETIC_LINING: {
    value: SCUBA_POND_FLOORS.SYNTHETIC_LINING,
    label: Localization.tr('Synthetic lining')
  }
})

export const TANK_MATERIALS = new TranslatableDictionary({
  FIBERGLASS: {
    value: SCUBA_TANK_MATERIALS.FIBERGLASS,
    label: Localization.tr('Fiberglass')
  },
  POLYETHYLENE: {
    value: SCUBA_TANK_MATERIALS.POLYETHYLENE,
    label: Localization.tr('Polyethylene')
  },
  PLASTIC_LINER: {
    value: SCUBA_TANK_MATERIALS.PLASTIC_LINER,
    label: Localization.tr('Plastic liner')
  },
  GALVANIZED_STEEL: {
    value: SCUBA_TANK_MATERIALS.GALVANIZED_STEEL,
    label: Localization.tr('Galvanized steel')
  },
  CONCRETE: {
    value: SCUBA_TANK_MATERIALS.CONCRETE,
    label: Localization.tr('Concrete')
  },
  CEMENT_BLOCK: {
    value: SCUBA_TANK_MATERIALS.CEMENT_BLOCK,
    label: Localization.tr('Cement block')
  }
})

export const WATER_TYPES = new TranslatableDictionary({
  FRESH: {
    value: SCUBA_WATER_TYPES.FRESH,
    label: Localization.tr('Fresh')
  },
  SALT: {
    value: SCUBA_WATER_TYPES.SALT,
    label: Localization.tr('Salt')
  },
  BRACKISH: {
    value: SCUBA_WATER_TYPES.BRACKISH,
    label: Localization.tr('Brackish')
  }
})

export class ContainerModel extends Model {
  constructor(c = {}) {
    super(c)
    this.organization = new ObjectProperty({
      label: Localization.tr('Organization'),
      value: c.organization,
      type: OrganizationModel,
      formatValue: value => (value ? value.name.formattedValue : null)
    })

    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: c.name
    })

    this.populationId = new TextProperty({
      label: Localization.tr('Population Id'),
      value: c.populationId
    })

    this.populationName = new TextProperty({
      label: Localization.tr('Population'),
      value: c.populationName,
      formatValue: value => value || '-'
    })

    this.category = new TextProperty({
      label: Localization.tr('Category'),
      value: c.category,
      formatValue: value => CONTAINER_CATEGORIES.format(value)
    })

    this.type = new TextProperty({
      label: Localization.tr('Type'),
      value: c.type,
      formatValue: value =>
        c.category === CONTAINER_CATEGORIES.POND.value ? POND_TYPES.format(value) : TANK_TYPES.format(value)
    })

    this.latitude = new NumberProperty({
      label: Localization.tr('Latitude'),
      value: c.latitude,
      step: 0.00001
    })

    this.longitude = new NumberProperty({
      label: Localization.tr('Longitude'),
      value: c.longitude,
      step: 0.00001
    })

    this.length = new NumberProperty({
      label: Localization.tr('Length'),
      value: c.length,
      unit: Localization.tr('m'),
      step: 0.01
    })

    this.width = new NumberProperty({
      label: Localization.tr('Width'),
      value: c.width,
      unit: Localization.tr('m'),
      step: 0.01
    })

    this.area = new NumberProperty({
      label: Localization.tr('Area'),
      value: c.area,
      unit: Localization.tr('hectares'),
      step: 0.0001
    })

    this.volume = new NumberProperty({
      label: Localization.tr('Volume'),
      value: c.volume,
      unit: Localization.tr('L'),
      step: 0.01
    })

    this.avgDepth = new NumberProperty({
      label: Localization.tr('Average Depth'),
      value: c.avgDepth,
      unit: Localization.tr('m'),
      step: 0.01
    })

    this.slope = new NumberProperty({
      label: Localization.tr('Slope'),
      value: c.slope,
      unit: Localization.tr('degrees'),
      step: 0.01
    })

    this.floorMaterial = new TextProperty({
      label:
        c.category === CONTAINER_CATEGORIES.POND.value ? Localization.tr('Pond floor') : Localization.tr('Material'),
      value: c.floorMaterial,
      formatValue: value =>
        c.category === CONTAINER_CATEGORIES.POND.value ? POND_FLOORS.format(value) : TANK_MATERIALS.format(value)
    })

    this.location = new TextProperty({
      label: Localization.tr('Location'),
      value: c.location,
      formatValue: value => TANK_LOCATIONS.format(value)
    })

    this.waterType = new TextProperty({
      label: Localization.tr('Water type'),
      value: c.waterType,
      formatValue: value => WATER_TYPES.format(value)
    })

    this.carryingCapacity = new NumberProperty({
      label: Localization.tr('Carrying capacity'),
      value: c.carryingCapacity,
      unit: Localization.tr('kg/m3'),
      step: 0.01
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: c.comment
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: c.comment
    })

    this.ascCertificationNumber = new TextProperty({
      label: Localization.tr('ASC (production unit) certification number'),
      value: c.ascCertificationNumber
    })
  }

  static getContainerText(category) {
    return category.value === CONTAINER_CATEGORIES.TANK.value
      ? CONTAINER_CATEGORIES.TANK.label
      : CONTAINER_CATEGORIES.POND.label
  }

  static getContainerUnit(category) {
    return category.value === CONTAINER_CATEGORIES.TANK.value
      ? CONTAINER_CATEGORIES.TANK.unit
      : CONTAINER_CATEGORIES.POND.unit
  }
}
