import { Property } from './property.js'
export class ObjectProperty extends Property {
  constructor({ value, label, formatValue, type } = {}) {
    super({
      value: value || (type ? new type() : {}), // eslint-disable-line new-cap
      label,
      formatValue: formatValue || (v => '')
    })
  }
}
