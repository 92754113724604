export class TranslatableDictionary {
  constructor(entries = {}) {
    this.entries = entries
    Object.assign(this, entries)
  }

  values() {
    return Object.keys(this.entries).map(obj => {
      return this.entries[obj]
    })
  }

  format(val) {
    if (!val) {
      return
    }

    return this.values().find(t => t.value === val).label
  }
}
