import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { TextProperty } from '../models/properties.js'
import { WARNING_TYPES } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'

export class WarningModel extends Model {
  constructor(w = {}) {
    super(w)

    const { formattedTitle, formattedBody } = this._formatType(w.type)

    this.type = new TextProperty({
      label: Localization.tr('Type'),
      value: w.type
    })

    this.title = new TextProperty({
      label: Localization.tr('Title'),
      value: formattedTitle
    })

    this.body = new TextProperty({
      label: Localization.tr('Description'),
      value: formattedBody
    })
  }

  _formatType(type) {
    switch (type) {
      case WARNING_TYPES.ORGANISMS_TOO_SMALL_FOR_SIZE_DISTRIBUTION:
        return {
          formattedTitle: Localization.tr('No length distribution generated'),
          formattedBody: Localization.tr(
            'The organisms in this sample are too small to generate a length distribution.'
          )
        }
      case WARNING_TYPES.ORGANISMS_TOO_SMALL_FOR_WEIGHT_DISTRIBUTION:
        return {
          formattedTitle: Localization.tr('No weight distribution generated'),
          formattedBody: Localization.tr(
            'The organisms in this sample are too small to generate a weight distribution.'
          )
        }
      case WARNING_TYPES.TOO_FEW_ORGANISMS_FOR_CALIBRATION:
        return {
          formattedTitle: Localization.tr('Count too low'),
          formattedBody: Localization.tr('The number of animals in this sample was below the minimum supported limit.')
        }
      case WARNING_TYPES.TOO_MANY_ORGANISMS_FOR_CALIBRATION:
        return {
          formattedTitle: Localization.tr('Count too high'),
          formattedBody: Localization.tr('The number of animals in this sample was above the maximum supported limit.')
        }
      case WARNING_TYPES.ORGANISMS_TOO_SMALL:
        return {
          formattedTitle: Localization.tr('Size too small'),
          formattedBody: Localization.tr('The animals in this sample were smaller than the minimum supported size.')
        }
      case WARNING_TYPES.ORGANISMS_TOO_LARGE:
        return {
          formattedTitle: Localization.tr('Size too large'),
          formattedBody: Localization.tr('The animals in this sample were larger than the maximum supported size.')
        }
      case WARNING_TYPES.IMAGE_TOO_DARK:
        return {
          formattedTitle: Localization.tr('Sample too dark'),
          formattedBody: Localization.tr('The water in this sample is not clear enough.')
        }
      case WARNING_TYPES.IMAGE_TOO_BRIGHT:
        return {
          formattedTitle: Localization.tr('Sample too bright'),
          formattedBody: Localization.tr('Device lid was likely off or not positioned correctly.')
        }
      case WARNING_TYPES.IMAGE_TOO_CLUSTERED:
        return {
          formattedTitle: Localization.tr('Too much clustering'),
          formattedBody: Localization.tr('The animals in this sample were too clustered together.')
        }
      default:
        return {
          formattedTitle: Localization.tr('Sample error'),
          formattedBody: Localization.tr('This sample contains an unspecified error.')
        }
    }
  }
}
