import { NumberProperty, PercentageProperty, TextProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class FeedCheckModel extends Model {
  constructor(f = {}) {
    super(f)
    this.index = new TextProperty({
      label: Localization.tr('#'),
      value: f.index
    })

    this.feedRemaining = new PercentageProperty({
      label: Localization.tr('Feed left'),
      value: f.feedRemaining
    })

    this.deadAnimals = new NumberProperty({
      label: Localization.tr('Dead count'),
      value: f.deadAnimals,
      unit: Localization.tr('pieces'),
      min: 0,
      step: 1
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: f.comment
    })
  }
}
