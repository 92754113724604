import { DeepwaterHarvestBreakdownMapper } from './deepwater/deepwater-harvest-breakdown-mapper.js'
import { HarvestModel } from './harvest-model.js'
import { ModelConverter } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { StateMapper } from '../models/state-mapper.js'

export class HarvestModelConverter extends ModelConverter {
  convert(m) {
    const application = StateMapper.findApplication({
      id: m.applicationId
    })

    let breakdown = application.harvestBreakdown.value
    if (m.breakdown) {
      breakdown = DeepwaterHarvestBreakdownMapper.convert(m.breakdown)
    }

    return new HarvestModel(
      Object.assign({}, m, {
        application,
        breakdown
      })
    )
  }
}
