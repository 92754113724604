import { DeepwaterSessionMapper } from '../sessions/deepwater/deepwater-session-mapper.js'
import { Functional } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { ModelConverter } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { MonitoringModel } from './monitoring-model.js'
import { PictureModel } from '../pictures/picture-model.js'
import { StateMapper } from '../models/state-mapper.js'
import { WeightGroupModel } from './weight-group-model.js'

export class MonitoringModelConverter extends ModelConverter {
  convert(p) {
    const application = StateMapper.findApplication({
      id: p.applicationId
    })

    let sessions = null
    if (p.sessions) {
      sessions = p.sessions
        .sort(
          Functional.sort({
            by: x => {
              return x.dateTime
            },
            ascending: true
          })
        )
        .map((session, index) => DeepwaterSessionMapper.fromDto({ ...session, index }))
    }

    let sessionPictures = null
    if (p.sessionPictures) {
      sessionPictures = p.sessionPictures.map(sp => new PictureModel(sp))
    }

    let weightGroups = null
    if (p.weightGroups) {
      weightGroups = p.weightGroups.map(wg => new WeightGroupModel(wg))
    }

    return new MonitoringModel(
      Object.assign({}, p, {
        application,
        sessions,
        sessionPictures,
        weightGroups
      })
    )
  }
}
