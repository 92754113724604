import { Functional } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Property } from './properties.js'

export class Model {
  constructor(m = {}) {
    this.__base = m
    this.id = new Property({
      value: m.id
    })
  }

  serialize() {
    return Functional.deepCopy(this.__base)
  }
}
