import { PERSISTED_STATE_KEY, Persistence } from '../data/persistence.js'

import { Analytics } from '../libs/analytics.js'
import { ApplicationModel } from '../data/applications/application-model.js'
import { CustomerModel } from '../data/customers/customer-model.js'
import { OrganizationModel } from '../data/organizations/organization-model.js'
import { Path } from '../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Platform } from '../libs/platform.js'
import { SeaElementMixin } from './element/sea-element.js'
import { UserModel } from '../data/users/user-model.js'
import { dedupingMixin } from '../../node_modules/@polymer/polymer/lib/utils/mixin.js'

const defaultState = {
  authorization: null,
  user: null,
  customer: null,
  organization: null,
  organizationAmount: 0,
  organizationApplications: [],
  selectedApplications: {
    counts: null,
    populations: null,
    shipments: null,
    receptions: null
  }
}

function persistState(state) {
  const serializedState = Object.assign({}, state, {
    user: state.user ? state.user.serialize() : null,
    customer: state.customer ? state.customer.serialize() : null,
    organization: state.organization ? state.organization.serialize() : null,
    organizationApplications: (state.organizationApplications || []).map(a => a.serialize())
  })

  Persistence.save('state', serializedState)
}

function loadPersistedState() {
  const serializedState = Persistence.find('state')

  if (!serializedState) {
    return defaultState
  }

  return Object.assign({}, defaultState, serializedState, {
    user: serializedState.user ? new UserModel(serializedState.user) : null,
    customer: serializedState.customer ? new CustomerModel(serializedState.customer) : null,
    organization: serializedState.organization ? new OrganizationModel(serializedState.organization) : null,
    organizationApplications: (serializedState.organizationApplications || []).map(a =>
      ApplicationModel.fromSerialized(a)
    )
  })
}

let __currentState = loadPersistedState()

export const GlobalMediatorMixin = dedupingMixin(
  Base =>
    class GlobalMediatorMixinClass extends SeaElementMixin(Base) {
      static get properties() {
        return {
          state: {
            type: Object,
            value: __currentState,
            observer: '__stateChanged'
          }
        }
      }

      constructor() {
        super()
        this._storageListener = this.__stateListener.bind(this)
        this._unauthorizedListener = this.__onUnauthorizedError.bind(this)
      }

      connectedCallback() {
        super.connectedCallback()
        this.addEventListener('app-authorize', this._authorize)
        this.addEventListener('app-unauthorize', this._unauthorize)
        this.addEventListener('app-update-application', this._updateApplication)
        this.addEventListener('app-select-customer', this._selectCustomer)
        this.addEventListener('app-deselect-customer', this._deselectCustomer)
        this.addEventListener('app-select-organization', this._selectOrganization)
        this.addEventListener('app-deselect-organization', this._deselectOrganization)
        this.addEventListener('app-select-application', this._selectApplication)
        this.addEventListener('app-update-organization', this._updateOrganization)
        this.addEventListener('app-update-customer', this._updateCustomer)
        this.addEventListener('app-update-user', this._updateUser)

        window.addEventListener('http-unauthorized', this._unauthorizedListener)
        window.addEventListener('storage', this._storageListener)
      }

      disconnectedCallback() {
        window.removeEventListener('http-unauthorized', this._unauthorizedListener)
        window.removeEventListener('storage', this._storageListener)
      }

      get currentState() {
        return __currentState
      }

      async _authorize(event) {
        const { authorization, loadUser, redirectTo } = event.detail
        this.__updateState({ authorization })

        let redirectPath = redirectTo
        if (loadUser) {
          const { user, organizationAmount, organizations } = await loadUser()
          this.__updateState({ user, organizationAmount })
          if (!redirectPath) {
            if (user.isXpertsea.value) {
              if (user.isTransactionsAdmin()) {
                redirectPath = '/react/transactions'
              } else {
                redirectPath = '/xpertsea/customers'
              }
            } else {
              redirectPath = organizationAmount === 1 ? `/organization/${organizations[0].id.value}` : '/organization'
            }
          }
        }

        this.raise('sea-navigation', {
          path: redirectPath
        })
      }

      _unauthorize(event) {
        const { redirectTo } = event ? event.detail || {} : {}

        Persistence.clear()
        Analytics.clear()
        __currentState = defaultState
        this.set('state', defaultState)

        Platform.redirectTo({ path: Path.addParams('/react/signin', { redirectTo }) })
      }

      _updateApplication(event) {
        const { application } = event.detail
        const { organizationApplications } = __currentState
        const index = organizationApplications.findIndex(a => a.id.value === application.id.value)
        organizationApplications[index] = application
        this.__updateState({ organizationApplications })
      }

      _selectCustomer(event) {
        const { customer } = event.detail
        const properties = {
          customer
        }
        if (this.state.organization && this.state.organization.customerId.value !== customer.id.value) {
          // make sure we don't have others customer organization on state
          properties.organization = null
          properties.organizationApplications = []
        }

        this.__updateState(properties)
      }

      _deselectCustomer(event) {
        this.__updateState({
          customer: null,
          organization: null,
          organizationApplications: []
        })
      }

      _selectOrganization(event) {
        const { organization, organizationApplications, customer } = event.detail
        const properties = {
          organization,
          organizationApplications
        }
        if (customer) {
          properties.customer = customer
        }

        this.__updateState(properties)
      }

      _deselectOrganization(event) {
        this.__updateState({
          organization: null,
          organizationApplications: []
        })
      }

      _selectApplication(event) {
        const { page, application } = event.detail

        if (__currentState.selectedApplications[page] === application.id.value) {
          // Prevent infinite event loop
          return
        }

        const selectedApplications = Object.assign({}, __currentState.selectedApplications, {
          [page]: application.id.value
        })

        this.__updateState({ selectedApplications })
      }

      _updateOrganization(event) {
        const { organization } = event.detail
        this.__updateState({ organization })
      }

      _updateCustomer(event) {
        const { customer } = event.detail
        this.__updateState({ customer })
      }

      _updateUser(event) {
        const { user } = event.detail
        this.__updateState({ user })
      }

      __onUnauthorizedError() {
        if (this.state.user) {
          this._unauthorize()
        }
      }

      __updateState(partialState) {
        __currentState = Object.assign({}, __currentState, partialState)
        this.set('state', __currentState)
      }

      __stateChanged(state) {
        persistState(state)
        Platform.dispatchEvent(new CustomEvent('app-state-changed', { bubbles: true, detail: state }))
      }

      __stateListener(event) {
        if (event.key && event.key.includes(PERSISTED_STATE_KEY)) {
          const state = Persistence.find(PERSISTED_STATE_KEY)
          if (state && state.authorization == null) {
            this._unauthorize()
          }
        }
      }
    }
)
