import { html } from '../components/element/sea-element.js'
import { register } from './style-module.js'

register({
  name: 'animations',
  template: html`
    <style>
      @keyframes fadein {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      @keyframes fadeout {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }

      @keyframes dim {
        from {
          opacity: 1;
        }

        to {
          opacity: 0.5;
        }
      }
    </style>
  `
})
