import { LanguageDto } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { es } from './languages/es.js'
import { fr } from './languages/fr.js'
import { id } from './languages/id.js'
import { ja } from './languages/ja.js'
/* eslint-disable-next-line camelcase */
import { pt_BR } from './languages/pt-BR.js'
import { th } from './languages/th.js'
import { vi } from './languages/vi.js'
/* eslint-disable-next-line camelcase */
import { zh_Hans } from './languages/zh-Hans.js'

export class SupportedLanguages {
  static fromCode(languageCode) {
    return SupportedLanguages.list.find(language => language.code === languageCode)
  }

  static get list() {
    return [
      new LanguageDto({
        code: 'en',
        label: 'English',
        languageModule: {},
        messages: {
          reloadInYourLanguage: 'Reload in english?',
          yes: 'Yes',
          no: 'No'
        }
      }),
      new LanguageDto({
        code: 'es',
        label: 'Español',
        languageModule: es,
        messages: {
          reloadInYourLanguage: 'Recargar en español?',
          yes: 'Sí',
          no: 'No'
        }
      }),
      new LanguageDto({
        code: 'fr',
        label: 'Français',
        languageModule: fr,
        messages: {
          reloadInYourLanguage: 'Recharger en français?',
          yes: 'Oui',
          no: 'Non'
        }
      }),
      new LanguageDto({
        code: 'id',
        label: 'Indonesia',
        languageModule: id,
        messages: {
          reloadInYourLanguage: 'Muat ulang di Indonesia?',
          yes: 'Iya nih',
          no: 'Tidak'
        }
      }),
      new LanguageDto({
        code: 'pt-BR',
        label: 'Português do Brasil',
        languageModule: pt_BR,
        messages: {
          reloadInYourLanguage: 'Recarregar em português do Brasil?',
          yes: 'Sim',
          no: 'Não'
        }
      }),
      new LanguageDto({
        code: 'vi',
        label: 'Tiếng Việt',
        languageModule: vi,
        messages: {
          reloadInYourLanguage: 'Tải lại bằng tiếng việt?',
          yes: 'Vâng',
          no: 'Không'
        }
      }),
      new LanguageDto({
        code: 'th',
        label: 'ไทย',
        languageModule: th,
        messages: {
          reloadInYourLanguage: 'โหลดเป็นภาษาไทย?',
          yes: 'ใช่',
          no: 'ไม่'
        }
      }),
      new LanguageDto({
        code: 'ja',
        label: '日本語',
        languageModule: ja,
        messages: {
          reloadInYourLanguage: '日本語でリロードしますか？',
          yes: 'はい',
          no: 'いいえ'
        }
      }),
      new LanguageDto({
        code: 'zh-Hans',
        label: '简体中文',
        languageModule: zh_Hans,
        messages: {
          reloadInYourLanguage: '用中文重新加载？',
          yes: '是',
          no: '没有'
        }
      })
    ]
  }
}
