import {
  BooleanProperty,
  CollectionProperty,
  DateTimeProperty,
  ObjectProperty,
  TextProperty
} from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { ROLES } from '@xpertsea/scuba/dist/esm/index.js'

export const READ_ONLY_ROLES = [ROLES.DATA_COLLECTOR, ROLES.TRAINER, ROLES.MEMBER]
export const XPERTSEA_ADMIN_ROLES = [ROLES.ADMIN, ROLES.SUDO]
export const XPERTSEA_ROLES = [ROLES.ADMIN, ROLES.SUDO, ROLES.TECH_REP]

export class UserModel extends Model {
  constructor(u = {}) {
    super(u)
    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: u.name
    })

    this.displayName = new TextProperty({
      label: Localization.tr('Name'),
      value: u.displayName
    })

    this.email = new TextProperty({
      label: Localization.tr('Email'),
      value: u.email
    })

    this.phone = new TextProperty({
      label: Localization.tr('Phone'),
      value: u.phone
    })

    this.lastConnectionDate = new DateTimeProperty({
      label: Localization.tr('Last activity'),
      value: u.lastConnectionDate,
      formattedDefault: Localization.tr('Never connected')
    })

    this.roles = new CollectionProperty({
      label: Localization.tr('Roles'),
      value: u.roles
    })

    this.isXpertsea = new BooleanProperty({
      label: Localization.tr('XpertSea'),
      value: u.isXpertsea
    })

    this.language = new TextProperty({
      label: Localization.tr('Language'),
      value: u.language
    })

    this.timezone = new TextProperty({
      label: Localization.tr('Time zone'),
      value: u.timezone
    })

    this.notificationSettings = new ObjectProperty({
      label: Localization.tr('Notifications'),
      value: u.notificationSettings
    })

    this.canSendNotifications = new BooleanProperty({
      label: Localization.tr('Send notifications rights'),
      value: u.canSendNotifications
    })

    this.isSuspended = new BooleanProperty({
      label: Localization.tr('Status'),
      value: u.isSuspended,
      formatValue: value => {
        return value ? Localization.tr('Suspended') : Localization.tr('Active')
      }
    })
  }

  getCustomerId() {
    const firstCustomerRole = this.roles.value.find(r => r.customerId != null)
    return firstCustomerRole ? firstCustomerRole.customerId : null
  }

  getOrganizationIdsFromRoles() {
    const organizations = new Set()

    for (const role of this.roles.value) {
      if (role.organizationId) {
        organizations.add(role.organizationId)
      }
    }

    return organizations
  }

  getRole({ organization, customer }) {
    let role = null

    if (customer) {
      role = this.roles.value.find(r => r.customerId === customer.id.value)
    }

    if (!role && organization) {
      role = this.roles.value.find(
        r => r.organizationId === organization.id.value || r.customerId === organization.customerId.value
      )
    }

    if (!role && this.isXpertsea.value) {
      role = this.roles.value.find(r =>
        [ROLES.TRANSACTIONS_ADMIN, ROLES.TECH_REP, ROLES.ADMIN, ROLES.SUDO].includes(r.role)
      )
    }

    return role
      ? {
          label: Localization.tr('Role'),
          value: role,
          formattedValue: this._formatRoleLabel(role.role)
        }
      : null
  }

  isSuspendedIn({ organization, customer }) {
    if (!organization && !customer) {
      return this.isSuspended
    }

    const role = this.getRole({ organization, customer })

    return {
      label: Localization.tr('Status'),
      value: role.value.blocked,
      formattedValue: role.value.blocked ? Localization.tr('Suspended') : Localization.tr('Active')
    }
  }

  hasAccessTo({ module, feature }) {
    if (this.isSuperAdministrator()) {
      return true
    }

    switch (module) {
      case 'administrators':
        return this.hasRoles(XPERTSEA_ADMIN_ROLES)

      case 'alerts-summary':
        if (!feature) {
          return false
        }

        switch (feature) {
          case 'water-alerts':
            return this.isXpertsea.value || this.isCustomer()
          default:
            return false
        }

      case 'batches': {
        if (!feature) {
          return this.hasRoles(XPERTSEA_ROLES)
        }

        return this.hasRoles(XPERTSEA_ADMIN_ROLES)
      }

      case 'devices': {
        if (!feature) {
          return true
        }

        switch (feature) {
          case 'edit-applications':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          case 'edit':
            return this.hasRoles(XPERTSEA_ROLES)

          case 'delete':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          case 'settings':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          case 'transfer':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          default:
            return false
        }
      }

      case 'organizations': {
        if (!feature) {
          return this.hasRoles(XPERTSEA_ROLES)
        }

        switch (feature) {
          case 'edit':
            return this.hasRoles([...XPERTSEA_ROLES, ROLES.TRANSACTIONS_ADMIN])

          case 'transfer':
          case 'change-status':
          case 'review':
          case 'enable-asc':
          case 'enable-feedRx':
          case 'enable-mobile-monitoring':
          case 'enable-mobile-trading':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          default:
            return false
        }
      }

      case 'customers':
        return this.hasRoles([...XPERTSEA_ADMIN_ROLES, ROLES.TRANSACTIONS_ADMIN])

      case 'users': {
        if (!feature) {
          return this.hasRoles(XPERTSEA_ROLES)
        }

        switch (feature) {
          case 'invite-tech-reps':
            return this.hasRoles(XPERTSEA_ADMIN_ROLES)

          default:
            return false
        }
      }

      case 'notifications':
        return Boolean(this.canSendNotifications.value)

      case 'transactions':
        return this.hasRoles(XPERTSEA_ADMIN_ROLES) || this.isTransactionsAdmin()

      default:
        return false
    }
  }

  hasAccessToCustomerFeature({ module, feature, customer }) {
    if (this.isSuperAdministrator()) {
      return true
    }

    const role = this.getRole({ customer }).value.role

    switch (module) {
      case 'customers': {
        if (!feature) {
          return true
        }

        switch (feature) {
          case 'edit':
            return [ROLES.ADMIN, ROLES.CUSTOMER_ADMIN, ROLES.TRANSACTIONS_ADMIN].includes(role)

          default:
            return false
        }
      }

      case 'organizations': {
        if (!feature) {
          return true
        }

        switch (feature) {
          case 'create':
            return [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN].includes(role)

          case 'edit':
            return [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN, ROLES.PROCESSOR_ADMIN].includes(role)

          case 'edit-special-fields':
            return [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN].includes(role)

          default:
            return false
        }
      }

      case 'users': {
        return [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN].includes(role)
      }

      default:
        return false
    }
  }

  hasAccessToOrganizationFeature({ module, feature, organization }) {
    if (this.isSuperAdministrator()) {
      return true
    }

    const role = this.getRole({ organization }).value.role
    const isEcuadorianOrganization = organization.country.value === 'ecu'

    switch (module) {
      case 'users': {
        switch (feature) {
          case 'invite':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN, ROLES.SUPERVISOR].includes(role)

          case 'invite-data-collectors':
            return [ROLES.SUDO, ROLES.ADMIN].includes(role)

          case 'edit':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN, ROLES.SUPERVISOR].includes(role)

          case 'view':
            return ![ROLES.TECHNICIAN].includes(role)

          case 'delete':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN, ROLES.SUPERVISOR].includes(role)

          default:
            return false
        }
      }

      case 'organizations': {
        switch (feature) {
          case 'create':
            return (
              [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN].includes(role) ||
              (isEcuadorianOrganization && [ROLES.TRANSACTIONS_ADMIN].includes(role))
            )

          case 'view':
            return (
              ![ROLES.TECHNICIAN].includes(role) ||
              (isEcuadorianOrganization && [ROLES.TRANSACTIONS_ADMIN].includes(role))
            )

          case 'edit':
            return (
              [ROLES.ADMIN, ROLES.TECH_REP, ROLES.CUSTOMER_ADMIN, ROLES.MANAGER].includes(role) ||
              (isEcuadorianOrganization && [ROLES.TRANSACTIONS_ADMIN].includes(role))
            )

          case 'delete':
            return (
              [ROLES.ADMIN].includes(role) || (isEcuadorianOrganization && [ROLES.TRANSACTIONS_ADMIN].includes(role))
            )

          case 'see-activity':
            return ![ROLES.TRANSACTIONS_ADMIN].includes(role)

          default:
            return false
        }
      }

      case 'containers': {
        switch (feature) {
          case 'create':
            return !READ_ONLY_ROLES.includes(role)

          case 'view':
            return !READ_ONLY_ROLES.includes(role)

          case 'edit':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN].includes(role)

          case 'delete':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN].includes(role)

          default:
            return false
        }
      }

      case 'populations': {
        switch (feature) {
          case 'create':
            return !READ_ONLY_ROLES.includes(role)

          case 'view':
            return !READ_ONLY_ROLES.includes(role)

          case 'edit':
            return !READ_ONLY_ROLES.includes(role)

          case 'delete':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN].includes(role)

          default:
            return false
        }
      }

      case 'monitorings': {
        switch (feature) {
          case 'activity':
            return !READ_ONLY_ROLES.includes(role)

          case 'create':
            return !READ_ONLY_ROLES.includes(role)

          case 'view':
            return !READ_ONLY_ROLES.includes(role)

          case 'edit':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN].includes(role)

          case 'delete':
            return ![...READ_ONLY_ROLES, ROLES.TECHNICIAN].includes(role)

          default:
            return false
        }
      }

      case 'counts': {
        switch (feature) {
          case 'activity':
            return ![ROLES.TRAINER].includes(role)

          case 'view':
            return ![ROLES.TRAINER].includes(role)

          case 'delete':
            return !READ_ONLY_ROLES.includes(role)

          case 'edit':
            return !READ_ONLY_ROLES.includes(role)

          default:
            return false
        }
      }

      case 'sessions': {
        switch (feature) {
          case 'delete':
            return !READ_ONLY_ROLES.includes(role)
          default:
            return false
        }
      }

      case 'shipments': {
        switch (feature) {
          case 'activity':
            return !READ_ONLY_ROLES.includes(role)
          case 'view':
            return !READ_ONLY_ROLES.includes(role)
          default:
            return false
        }
      }

      case 'receptions': {
        switch (feature) {
          case 'activity':
            return !READ_ONLY_ROLES.includes(role)
          case 'view':
            return !READ_ONLY_ROLES.includes(role)
          default:
            return false
        }
      }

      case 'meals': {
        switch (feature) {
          case 'activity':
            return !READ_ONLY_ROLES.includes(role)
          default:
            return false
        }
      }

      case 'harvests': {
        switch (feature) {
          case 'activity':
            return !READ_ONLY_ROLES.includes(role)
          default:
            return false
        }
      }

      case 'devices': {
        switch (feature) {
          case 'view':
            return true
          default:
            return false
        }
      }

      case 'water-analysis': {
        switch (feature) {
          case 'activity':
            return true
          default:
            return false
        }
      }

      default:
        return false
    }
  }

  isCustomer() {
    return this.roles.value.some(r => r.customerId != null)
  }

  isSuperAdministrator() {
    return this.hasRoles([ROLES.SUDO])
  }

  isProcessorAdmin({ organization }) {
    const roleModel = this.getRole({ organization })
    return roleModel && roleModel.value && roleModel.value.role === ROLES.PROCESSOR_ADMIN
  }

  isTransactionsAdmin() {
    return this.hasRoles([ROLES.TRANSACTIONS_ADMIN])
  }

  hasRoles(roles) {
    return this.roles.value.some(r => roles.includes(r.role))
  }

  getType() {
    if (this.isXpertsea.value) {
      return 'xpertsea'
    }

    if (this.isCustomer()) {
      return 'enterprise'
    }

    return 'basic'
  }

  _formatRoleLabel(value) {
    switch (value) {
      case ROLES.CUSTOMER_ADMIN:
        return Localization.tr('Administrator')

      case ROLES.CUSTOMER_REP:
        return Localization.tr('Technical representative')

      case ROLES.DATA_COLLECTOR:
        return Localization.tr('Data collector')

      case ROLES.TRAINER:
        return Localization.tr('Annotator')

      case ROLES.MEMBER:
        return Localization.tr('Member')

      case ROLES.SUPERVISOR:
        return Localization.tr('Supervisor')

      case ROLES.MANAGER:
        return Localization.tr('Manager')

      case ROLES.TECHNICIAN:
        return Localization.tr('Technician')

      case ROLES.TECH_REP:
        return Localization.tr('XpertSea rep')

      case ROLES.ADMIN:
        return Localization.tr('XpertSea admin')

      case ROLES.SUDO:
        return Localization.tr('XpertSea super admin')

      case ROLES.PROCESSOR_ADMIN:
        return Localization.tr('Processor admin')

      case ROLES.TRANSACTIONS_ADMIN:
        return Localization.tr('Transactions admin')

      default:
        return Localization.tr('Unknown role')
    }
  }
}
