import { Localization } from '../../../libs/localization/localization.js'
import { NumberProperty } from './number-property.js'
import { Numbers } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

export class PercentageProperty extends NumberProperty {
  static toPercent(value) {
    const digitsToKeepAfterRoundingRatio = 10000
    const percentageRatio = 100
    return value != null ? Math.round(value * digitsToKeepAfterRoundingRatio) / percentageRatio : null
  }

  static getFormatValue(value, formatValue) {
    const valueAsPercent = PercentageProperty.toPercent(value)
    return formatValue
      ? PercentageProperty.getExistingFormatValue(valueAsPercent, formatValue)
      : PercentageProperty.getDefaultFormatValue(valueAsPercent)
  }

  static getExistingFormatValue(valueAsPercent, formatValue) {
    return () => {
      return valueAsPercent != null ? formatValue(valueAsPercent) : ''
    }
  }

  static getDefaultFormatValue(valueAsPercent) {
    return () => {
      return valueAsPercent != null ? Localization.toLocaleString(valueAsPercent) : ''
    }
  }

  static getFormattedValueWithUnit(value, formattedValue, unit, nullableValueHandler) {
    if (value != null || nullableValueHandler == null) {
      return formattedValue ? `${formattedValue}${unit}` : ''
    }

    return nullableValueHandler()
  }

  constructor({ value, label, formatValue, nullableValueHandler, errorMessage, min = 0, max = 100, step = 0.01 } = {}) {
    super({
      value,
      label,
      formatValue: PercentageProperty.getFormatValue(value, formatValue),
      nullableValueHandler,
      min,
      max,
      step,
      errorMessage
    })

    const stepCorrectionFactor = 100
    this.valueAsPercent = PercentageProperty.toPercent(value)
    this.step = step
    this.unit = '%'
    this.min = min
    this.max = max
    // HACK: divinding the step by 100 because step assosiated with the formatted value instead of the value itself.
    this.roundedValue = Numbers.getRoundedValue(this.value, this.step / stepCorrectionFactor)
    this.formattedValueWithUnit = PercentageProperty.getFormattedValueWithUnit(
      this.value,
      this.formattedValue,
      this.unit,
      nullableValueHandler
    )
  }
}
