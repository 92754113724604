import { NumberProperty, PercentageProperty } from '../models/properties.js'

import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class HarvestBreakdownModel extends Model {
  constructor(f = {}) {
    super(f)
    this.amountPerWeight = new NumberProperty({
      label: Localization.tr('Count/kg'),
      value: f.amountPerWeight,
      unit: Localization.tr('kg'),
      step: 0.01,
      formatValueWithUnit: (formattedValue, unit) => {
        return `${f.label}/${unit}`
      }
    })

    this.totalHarvested = new PercentageProperty({
      label: Localization.tr('Percentage of harvest'),
      value: f.totalHarvested
    })
  }

  export() {
    return {
      amountPerWeight: this.amountPerWeight.formattedValue,
      totalHarvested: this.totalHarvested.formattedValue
    }
  }
}
