import {
  CollectionProperty,
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  ObjectProperty,
  PercentageProperty,
  TextProperty
} from '../models/properties.js'

import { ApplicationModel } from '../applications/application-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { ProcessingStatusMixin } from '../../components/mixins/processing-status-mixin.js'
import { WorkflowContextModel } from '../workflow-context/workflow-context-model.js'

export class SessionModel extends ProcessingStatusMixin(Model) {
  constructor(s = {}) {
    super(s)
    this.index = new NumberProperty({
      label: Localization.tr('#'),
      value: s.index,
      formatValue: value => `${Localization.toLocaleFixedString(value + 1)}`
    })

    this.applicationId = new TextProperty({
      label: Localization.tr('Application ID'),
      value: s.applicationId
    })

    this.application = new ObjectProperty({
      label: Localization.tr('App'),
      value: s.application,
      type: ApplicationModel,
      formatValue: value => value.name.formattedValue
    })

    this.applicationUnit = new TextProperty({
      label: Localization.tr('Unit'),
      value: this.application.value ? this.application.value.unit.value : null,
      formatValue: () =>
        this.application.value ? this.application.value.unit.formattedValue : Localization.tr('animals')
    })

    this.applicationLatinName = new TextProperty({
      label: Localization.tr('Species'),
      value: this.application.value ? this.application.value.latinName.value : null
    })

    this.applicationVersion = new TextProperty({
      label: Localization.tr('Application version'),
      value: s.applicationVersion
    })

    this.modelVersion = new TextProperty({
      label: Localization.tr('Model version (Original)'),
      value: s.modelVersion
    })

    this.countingPlatformVersion = new TextProperty({
      label: Localization.tr('Counting platform'),
      value: s.countingPlatformVersion,
      formatValue: value => `CP-${value}`
    })

    this.xpertviewVersion = new TextProperty({
      label: Localization.tr('XpertView'),
      value: s.xpertviewVersion,
      formatValue: value => `XVW-${value}`
    })

    this.deviceSerialNumber = new TextProperty({
      label: Localization.tr('Device'),
      value: s.deviceSerialNumber
    })

    this.organizationId = new TextProperty({
      label: Localization.tr('Organization ID'),
      value: s.organizationId
    })

    this.workflowContext = new ObjectProperty({
      label: Localization.tr('Workflow context'),
      value: s.workflowContext,
      type: WorkflowContextModel,
      formatValue: value => value.workflowId.value
    })

    this.picture = new ImageProperty({
      label: Localization.tr('Picture'),
      value: s.picture
    })

    this.payload = new ObjectProperty({
      label: Localization.tr('Payload'),
      value: s.payload
    })

    this.dateTime = new DateTimeProperty({
      label: Localization.tr('Created'),
      value: s.dateTime
    })

    this.uploadedTime = new DateTimeProperty({
      label: Localization.tr('Uploaded'),
      value: s.uploadedTime
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: s.comment
    })

    this.animalAmountCounted = new NumberProperty({
      label:
        this.application.value && this.application.value.supportsConcentration.value
          ? Localization.tr('Concentration')
          : Localization.tr('Total count'),
      value: s.animalAmountCounted,
      unit: this.applicationUnit.formattedValue,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.counts = new CollectionProperty({
      label: Localization.tr('Samples'),
      value: s.counts
    })

    this.averageLength = new NumberProperty({
      label: Localization.tr('Avg length'),
      value: s.averageLength,
      unit: Localization.tr('mm'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.coefficientVariation = new PercentageProperty({
      label: Localization.tr('Length CV'),
      value: s.coefficientVariation,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.sizeDistribution = new CollectionProperty({
      label: Localization.tr('Size distribution'),
      value: s.sizeDistribution
    })

    this.sizeHistogram = new ObjectProperty({
      label: Localization.tr('Size histogram'),
      value: s.sizeHistogram
    })

    this.averageWeight = new NumberProperty({
      label: Localization.tr('Avg weight'),
      value: s.averageWeight,
      unit: Localization.tr('g'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.weightCoefficientVariation = new PercentageProperty({
      label: Localization.tr('Weight CV'),
      value: s.weightCoefficientVariation,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.weightDistribution = new CollectionProperty({
      label: Localization.tr('Weight distribution'),
      value: s.weightDistribution
    })

    this.weightHistogram = new ObjectProperty({
      label: Localization.tr('Weight histogram'),
      value: s.weightHistogram
    })

    this.totalWeight = new NumberProperty({
      label: Localization.tr('Total weight'),
      value: s.totalWeight,
      unit: Localization.tr('g'),
      step: 0.01,
      nullableValueHandler: this._getProcessingOrWarningsFormatValue()
    })

    this.warnings = new CollectionProperty({
      label: Localization.tr('Warnings'),
      value: s.warnings
    })
  }
}
