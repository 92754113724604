import '../../node_modules/@polymer/iron-media-query/iron-media-query.js'
import '../../node_modules/@polymer/paper-toast/paper-toast.js'
import '../../node_modules/@polymer/polymer/lib/elements/dom-repeat.js'
import './buttons/sea-button.js'
import '../styles/reset.js'
import '../styles/shared.js'

import { SeaElement, html } from './element/sea-element.js'

export const NOTIFICATION_TYPES = {
  PONCTUAL: 'ponctual',
  PERMANENT: 'permanent'
}

const DEFAULT_NOTIFICATION_DURATION = 3000

export class AppNotification extends SeaElement {
  static get template() {
    return html`
      <style include="reset shared">
        paper-toast {
          font-size: 1rem;
          display: flex;
          align-items: center;
        }

        .choices {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: 500;
          color: var(--sea-light-accent-color);
        }
      </style>

      <iron-media-query query="max-width: 768px" query-matches="{{_isSmallScreen}}"></iron-media-query>
      <paper-toast
        id="toast"
        class$="[[_computeFitsBottom(_isSmallScreen)]]"
        duration="[[_computeDuration(notification)]]"
        text="[[notification.message]]"
        on-iron-overlay-closed="_clear"
      >
        <div class="choices">
          <dom-repeat items="[[notification.choices]]" as="choice">
            <template>
              <sea-button on-tap="_choiceMade">[[choice.text]]</sea-button>
            </template>
          </dom-repeat>
        </div>
      </paper-toast>
    `
  }

  static get properties() {
    return {
      notification: {
        type: Object,
        value: null,
        observer: '_notificationChanged'
      },
      _isSmallScreen: Boolean
    }
  }

  _computeFitsBottom(isSmallScreen) {
    return isSmallScreen ? 'fit-bottom' : ''
  }

  _computeDuration(notification) {
    if (!notification) {
      return DEFAULT_NOTIFICATION_DURATION
    }

    const { choices, duration = DEFAULT_NOTIFICATION_DURATION, type = NOTIFICATION_TYPES.PONCTUAL } = notification

    if (type === NOTIFICATION_TYPES.PERMANENT) {
      return 0
    }

    return choices ? 0 : duration
  }

  _notificationChanged(notification) {
    this.$.toast.close()
    if (notification) {
      this.$.toast.open()
    }
  }

  _choiceMade(event) {
    event.stopPropagation()
    const choice = this.notification.choices.find(c => c.text === event.target.textContent && c.action)

    if (choice) {
      choice.action()
    }

    this.$.toast.close()
  }

  _clear() {
    this.set('notification', null)
  }
}
customElements.define('app-notification', AppNotification)
