import { ElementMixin } from '../../../node_modules/@polymer/polymer/lib/mixins/element-mixin.js'
import { dedupingMixin } from '../../../node_modules/@polymer/polymer/lib/utils/mixin.js'

export const SeaElementMixin = dedupingMixin(
  Base =>
    class SeaElementMixinClass extends ElementMixin(Base) {
      static get properties() {
        return {
          /**
           * Configuration properties with truthy default values are bad.
           * Databind them to this property as a workaround, if you need them to be false.
           *
           * @type {Boolean}
           * @readonly
           * @default false
           */
          falsy: {
            type: Boolean,
            readonly: true,
            value: false
          }
        }
      }
      /**
       * Dispatches a bubbling composed CustomEvent with the provided details, if any.
       *
       * @param {String} name Event name (required)
       * @param {*} detail Event details (optional)
       * @returns {void}
       */

      raise(name, detail) {
        this.dispatchEvent(
          new CustomEvent(name, {
            bubbles: true,
            composed: true,
            detail
          })
        )
      }
    }
)
