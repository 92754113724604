import { Localization } from '../../../libs/localization/localization.js'
import { Numbers } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Property } from './property.js'

export class NumberProperty extends Property {
  static defaultFormatIntegerValue(value) {
    return value != null ? Localization.toLocaleString(Math.round(value)) : ''
  }

  static defaultFormatFloatValue(value, decimals) {
    return value != null ? Localization.toLocaleFixedString(value, decimals) : ''
  }

  static defaultFormatValueWithUnit(formattedValue, unit) {
    return formattedValue && unit ? `${formattedValue} ${unit}` : formattedValue
  }

  static getDefaultFormatValue(step) {
    const decimals = Numbers.countStepDecimals(step)

    if (decimals > 0) {
      return v => NumberProperty.defaultFormatFloatValue(v, decimals)
    }

    return NumberProperty.defaultFormatIntegerValue
  }

  static getFormatValueWithUnit(value, formattedValue, unit, formatValueWithUnit, nullableValueHandler) {
    if (value != null || nullableValueHandler == null) {
      if (formatValueWithUnit) {
        return value != null ? formatValueWithUnit(formattedValue, unit) : ''
      }

      return value != null ? NumberProperty.defaultFormatValueWithUnit(formattedValue, unit) : ''
    }

    return nullableValueHandler()
  }

  static getErrorMessage(min, max, step) {
    let errorMsg = ''
    if (min != null && max) {
      errorMsg = Localization.tr('${min} to ${max}', {
        '${min}': min,
        '${max}': max
      })
    } else if (min != null) {
      errorMsg = Localization.tr('Numbers above ${min}', {
        '${min}': min
      })
    } else if (max) {
      errorMsg = Localization.tr('Numbers below ${max}', {
        '${max}': max
      })
    }

    if (step) {
      const decimals = Numbers.countStepDecimals(step)
      if (decimals) {
        errorMsg += errorMsg ? Localization.tr(', ') : ''
        errorMsg += Localization.tr('${step} decimals', {
          '${step}': decimals
        })
      }
    }

    return errorMsg
  }

  constructor({
    value = null,
    label,
    unit = '',
    step = 1,
    min = null,
    max = null,
    formatValue,
    formatValueWithUnit,
    nullableValueHandler,
    errorMessage
  } = {}) {
    super({
      label,
      value,
      formatValue: formatValue || NumberProperty.getDefaultFormatValue(step),
      errorMessage: errorMessage || NumberProperty.getErrorMessage(min, max, step),
      nullableValueHandler
    })

    this.step = step
    this.min = min
    this.max = max
    this.unit = unit
    this.roundedValue = Numbers.getRoundedValue(this.value, this.step)
    this.formattedValueWithUnit = NumberProperty.getFormatValueWithUnit(
      this.value,
      this.formattedValue,
      this.unit,
      formatValueWithUnit,
      nullableValueHandler
    )
  }
}
