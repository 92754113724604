import { Localization } from './localization/localization.js'

export class Strings {
  static rangeLabel(i, currentAmount, nextAmount, translateFn) {
    if (i === 0) {
      return Localization.tr('< ${amount}', {
        '${amount}': nextAmount
      })
    } else if (!nextAmount) {
      return Localization.tr('> ${amount}', {
        '${amount}': currentAmount
      })
    }

    return Localization.tr('${current_amount} - ${next_amount}', {
      '${current_amount}': currentAmount,
      '${next_amount}': nextAmount
    })
  }
}
