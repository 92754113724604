import { DeepwaterWaterAnalysisMapper } from '../../../node_modules/@xpertsea/scuba/dist/esm/repositories/water-analysis/deepwater/deepwater-water-analysis-mapper.js'
import { Localization } from '../../libs/localization/localization.js'
import { ModelConverter } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { StateMapper } from '../models/state-mapper.js'
import { WaterAnalysisModel } from './water-analysis-model.js'

export class WaterAnalysisModelConverter extends ModelConverter {
  newModelInstance(options) {
    // need to do this to get all measurement types
    const dto = DeepwaterWaterAnalysisMapper.toDto({})
    return this.convert(Object.assign({}, dto, options))
  }

  convert(m) {
    let measurements = {}
    if (m.measurements) {
      measurements = m.measurements
        .map(measurement =>
          Object.assign({}, measurement, {
            unit: this._translateMeasurementUnit(measurement.unit)
          })
        )
        .reduce((obj, item, index) => {
          obj[this._mapTypes(item.measurementType)] = item
          return obj
        }, {})
    }

    return new WaterAnalysisModel(
      Object.assign(
        {},
        m,
        {
          application: StateMapper.findApplication({
            id: m.applicationId
          })
        },
        measurements
      )
    )
  }

  _mapTypes(type) {
    const types = {
      dissolved_oxygen: 'dissolvedOxygen',
      water_clarity: 'waterClarity',
      water_gravity: 'waterGravity',
      oxygen_saturation: 'oxygenSaturation',
      avg_hardness: 'avgHardness',
      total_dissolved_solids: 'tds',
      total_suspended_solids: 'tss',
      hydrogen_sulfide: 'hydrogenSulfide'
    }
    return types[type] || type
  }

  _translateMeasurementUnit(unit) {
    switch (unit) {
      case 'cm':
        return Localization.tr('cm')
      case 'PSU':
        return Localization.tr('PSU')
      case '°C':
        return Localization.tr('°C')
      case '%':
        return Localization.tr('%')
      case 'ppm':
        return Localization.tr('ppm')
      case 'ppt':
        return Localization.tr('ppt')
      case 'mg/L':
        return Localization.tr('mg/L')
      default:
        return unit
    }
  }
}
