import { Configuration } from '../../configuration.js'
const NAMESPACE = 'xpertsea::'
const PERSISTENCE_VERSION_KEY = 'version'
const SETTINGS_PREFIX = 'settings::'
export const PERSISTED_STATE_KEY = 'state'
export const PREVIOUS_APP_VERSION_KEY = 'previous-app-version'
export const WHATS_NEW_DISMISSED_KEY = 'whats-new-dismissed'
export class Persistence {
  static isUpToDate() {
    return this.find(PERSISTENCE_VERSION_KEY, 0) === Configuration.persistence.version
  }

  static find(key, defaultValue = null) {
    const stringifiedValue = localStorage.getItem(`${NAMESPACE}${key}`)
    return this.__parseValue(stringifiedValue, defaultValue)
  }

  static save(key, value) {
    localStorage.setItem(`${NAMESPACE}${key}`, JSON.stringify(value))
    return value
  }

  static remove(key) {
    localStorage.removeItem(`${NAMESPACE}${key}`)
  }

  static dismissWhatsNew() {
    localStorage.setItem(`${NAMESPACE}${WHATS_NEW_DISMISSED_KEY}`, true)
  }

  static resetWhatsNew(version) {
    localStorage.setItem(`${NAMESPACE}${PREVIOUS_APP_VERSION_KEY}`, JSON.stringify(version))
    localStorage.setItem(`${NAMESPACE}${WHATS_NEW_DISMISSED_KEY}`, false)
  }

  static clearHard() {
    localStorage.clear()
    this.save(PERSISTENCE_VERSION_KEY, Configuration.persistence.version)
  }

  static clear() {
    const itemsToBeKept = this.__findItemsToBeKeptFromClear()

    localStorage.clear()
    itemsToBeKept.forEach(item => localStorage.setItem(item.key, item.value))
  }

  static __findItemsToBeKeptFromClear() {
    return Object.entries(localStorage)
      .map(entry => ({
        key: entry[0],
        value: entry[1]
      }))
      .filter(item => {
        return (
          item.key.includes(NAMESPACE) &&
          (item.key.includes(SETTINGS_PREFIX) || item.key === `${NAMESPACE}${PERSISTENCE_VERSION_KEY}`)
        )
      })
  }

  static __parseValue(stringifiedValue, defaultValue = null) {
    return stringifiedValue != null ? JSON.parse(stringifiedValue) : defaultValue
  }
}
