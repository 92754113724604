import '../../node_modules/@polymer/iron-pages/iron-pages.js'
import '../styles/animations.js'

import { SeaElement, html } from './element/sea-element.js'

export class PageRouter extends SeaElement {
  static get template() {
    return html`
      <style include="animations">
        :host,
        iron-pages {
          width: 100%;
          height: 100%;
        }

        iron-pages ::slotted(.iron-selected) {
          animation: fadein 0.2s;
        }

        iron-pages ::slotted(:not(.iron-selected)) {
          animation: fadeout 0.2s;
        }
      </style>

      <iron-pages
        selected="[[_matchedPageInfo.routePattern]]"
        attr-for-selected="route-pattern"
        fallback-selection="/not-found"
      >
        <slot id="slot"></slot>
        <div route-pattern="/unauthorized"></div>
        <div route-pattern="/forbidden"></div>
        <div route-pattern="/not-found"></div>
      </iron-pages>
    `
  }

  static get properties() {
    return {
      isAuthorized: {
        type: Boolean,
        value: true
      },
      isAccessGranted: {
        type: Boolean,
        value: true
      },
      pages: Array,
      route: Object,
      _effectiveRoute: {
        type: Object,
        computed: '_computeEffectiveRoute(route, isAuthorized, isAccessGranted)'
      },
      _matchedPageInfo: {
        type: Object,
        computed: '_computeMatchedPageInfo(_effectiveRoute, pages, _notFoundPageInfo)',
        observer: '_observeMatchedPageInfo'
      },
      _notFoundPageInfo: {
        type: Object,
        computed: '_computeNotFoundPageInfo(pages)'
      }
    }
  }

  connectedCallback() {
    super.connectedCallback()
    const providedPages = this.$.slot.assignedNodes().filter(node => Boolean(node.tagName))
    const defaultPages = Array.from(this.shadowRoot.querySelectorAll('iron-pages>div'))
    this.set('pages', providedPages.concat(defaultPages))
  }

  _computeEffectiveRoute(route, isAuthorized, isAccessGranted) {
    if (!route) {
      return null
    }

    if (!isAuthorized) {
      return { path: '/unauthorized' }
    }

    if (!isAccessGranted) {
      return { path: '/forbidden' }
    }

    return route
  }

  _computeNotFoundPageInfo(pages) {
    const notFoundRoute = { path: '/not-found' }
    return this._findPageInfo({ route: notFoundRoute, pages })
  }

  _computeMatchedPageInfo(route, pages, notFoundPageInfo) {
    if (!route || !pages || !notFoundPageInfo) {
      return null
    }

    return this._findPageInfo({ route, pages }) || notFoundPageInfo
  }

  _observeMatchedPageInfo(newMatchedPageInfo, oldMatchedPageInfo) {
    this._unsetRouteInOldPage({ newMatchedPageInfo, oldMatchedPageInfo })
    if (!newMatchedPageInfo) {
      return
    }

    const { page, data: properties, tail } = newMatchedPageInfo
    properties.route = tail
    if (page.setProperties) {
      page.setProperties(properties)
    }

    if (!oldMatchedPageInfo || page !== oldMatchedPageInfo.page) {
      this.raise('page-selected', { page, properties })
    }
  }

  _findPageInfo({ route, pages }) {
    const routePatterns = pages ? pages.map(page => page.getAttribute('route-pattern')) : []
    return (
      pages.map(page => this._parseAsPageInfo({ page, route, routePatterns })).find(pageInfo => pageInfo.isMatched) ||
      null
    )
  }

  _parseAsPageInfo({ page, route, routePatterns }) {
    let isMatched = true
    const routePattern = page.getAttribute('route-pattern')
    const otherPatternParts = routePatterns.map(pattern => pattern.split('/')[1])
    const data = {}
    const tail = { path: '/' }

    const pathParts = route.path.split('/').filter(p => p !== '')
    const patternParts = routePattern.split('/').filter(p => p !== '')

    if (patternParts.length > pathParts.length || (patternParts.length === 0 && pathParts.length > 0)) {
      isMatched = false
    } else {
      pathParts.forEach((pathPart, index) => {
        const patternPart = patternParts[index]
        if (!patternPart) {
          tail.path = tail.path !== '/' ? `${tail.path}/${pathPart}` : `/${pathPart}`
        } else if (patternPart.startsWith(':') && !otherPatternParts.includes(pathPart)) {
          const parameterName = patternPart.split(':')[1]
          data[parameterName] = pathPart
        } else if (patternPart !== pathPart) {
          isMatched = false
        }
      })
    }

    return { page, routePattern, isMatched, data, tail }
  }

  _unsetRouteInOldPage({ newMatchedPageInfo, oldMatchedPageInfo }) {
    const canUnsetRoute = oldMatchedPageInfo && oldMatchedPageInfo.page && oldMatchedPageInfo.page.set
    if (canUnsetRoute) {
      const hasDifferentNewPage =
        !newMatchedPageInfo ||
        (newMatchedPageInfo && newMatchedPageInfo.page && newMatchedPageInfo.page !== oldMatchedPageInfo.page)

      if (hasDifferentNewPage) {
        oldMatchedPageInfo.page.set('route', null)
      }
    }
  }
}

customElements.define('page-router', PageRouter)
