import {
  DateProperty,
  DateTimeProperty,
  NumberProperty,
  ObjectProperty,
  PercentageProperty,
  TextProperty,
  TimeProperty
} from '../models/properties.js'

import { ApplicationModel } from '../applications/application-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class WaterAnalysisModel extends Model {
  constructor(
    wa = {
      salinity: {},
      temperature: {},
      waterClarity: {},
      oxygenSaturation: {},
      waterGravity: {},
      ph: {},
      dissolvedOxygen: {},
      alkalinity: {},
      nitrites: {},
      nitrates: {},
      ammonium: {},
      hardness: {},
      avgHardness: {},
      phosphates: {},
      magnesium: {},
      potassium: {},
      calcium: {},
      carbonate: {},
      bicarbonate: {},
      tds: {},
      tss: {},
      ammonia: {},
      iron: {},
      chlorine: {},
      hydrogenSulfide: {}
    }
  ) {
    super(wa)

    this.application = new ObjectProperty({
      label: Localization.tr('Species'),
      value: wa.application,
      type: ApplicationModel,
      formatValue: value => value.name.formattedValue
    })

    this.dateTime = new DateTimeProperty({
      label: Localization.tr('Date & time'),
      value: wa.dateTime
    })

    this.date = new DateProperty({
      label: Localization.tr('Date'),
      value: wa.dateTime
    })

    this.time = new TimeProperty({
      label: Localization.tr('Time'),
      value: wa.dateTime
    })

    this.populationName = new TextProperty({
      label: Localization.tr('Population Name'),
      value: wa.populationName
    })

    this.populationId = new TextProperty({
      label: Localization.tr('Population ID'),
      value: wa.populationId
    })

    this.readingType = new TextProperty({
      label: Localization.tr('Reading type'),
      value: wa.readingType
    })

    this.dissolvedOxygen = new NumberProperty({
      label: Localization.tr('Dissolved oxygen'),
      value: wa.dissolvedOxygen.value,
      unit: wa.dissolvedOxygen.unit,
      min: 0,
      max: 50,
      step: 0.001
    })

    this.ph = new NumberProperty({
      label: Localization.tr('pH'),
      value: wa.ph.value,
      min: 0,
      max: 14,
      unit: wa.ph.unit,
      step: 0.01
    })

    this.salinity = new NumberProperty({
      label: Localization.tr('Salinity'),
      value: wa.salinity.value,
      unit: wa.salinity.unit,
      min: 0,
      max: 100,
      step: 0.01
    })

    this.temperature = new NumberProperty({
      label: Localization.tr('Temperature'),
      value: wa.temperature.value,
      unit: wa.temperature.unit,
      min: -40,
      max: 40,
      step: 0.01
    })

    this.waterClarity = new NumberProperty({
      label: Localization.tr('Turbidity'),
      value: wa.waterClarity.value,
      unit: wa.waterClarity.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.waterGravity = new NumberProperty({
      label: Localization.tr('Water gravity'),
      value: wa.waterGravity.value,
      unit: wa.waterGravity.unit,
      min: 0,
      max: 50,
      step: 0.001
    })

    this.alkalinity = new NumberProperty({
      label: Localization.tr('Alkalinity (total)'),
      value: wa.alkalinity.value,
      unit: wa.alkalinity.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.nitrites = new NumberProperty({
      label: Localization.tr('Nitrites (NO2)'),
      value: wa.nitrites.value,
      unit: wa.nitrites.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.nitrates = new NumberProperty({
      label: Localization.tr('Nitrates (NO3)'),
      value: wa.nitrates.value,
      unit: wa.nitrates.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.ammonium = new NumberProperty({
      label: Localization.tr('Ammonium (NH4+)'),
      value: wa.ammonium.value,
      unit: wa.ammonium.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.oxygenSaturation = new PercentageProperty({
      label: Localization.tr('Oxygen saturation'),
      value: wa.oxygenSaturation.value,
      unit: wa.oxygenSaturation.unit,
      step: 0.01
    })

    this.hardness = new NumberProperty({
      label: Localization.tr('Total hardness'),
      value: wa.hardness.value,
      unit: wa.hardness.unit,
      min: 0,
      step: 0.01
    })

    this.avgHardness = new NumberProperty({
      label: Localization.tr('Avg hardness'),
      value: wa.avgHardness.value,
      unit: wa.avgHardness.unit,
      min: 0,
      step: 0.01
    })

    this.phosphates = new NumberProperty({
      label: Localization.tr('Phosphates'),
      value: wa.phosphates.value,
      unit: wa.phosphates.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.magnesium = new NumberProperty({
      label: Localization.tr('Magnesium'),
      value: wa.magnesium.value,
      unit: wa.magnesium.unit,
      min: 0,
      max: 100000,
      step: 0.01
    })

    this.potassium = new NumberProperty({
      label: Localization.tr('Potassium'),
      value: wa.potassium.value,
      unit: wa.potassium.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.calcium = new NumberProperty({
      label: Localization.tr('Calcium'),
      value: wa.calcium.value,
      unit: wa.calcium.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.carbonate = new NumberProperty({
      label: Localization.tr('Carbonate (CO3)'),
      value: wa.carbonate.value,
      unit: wa.carbonate.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.bicarbonate = new NumberProperty({
      label: Localization.tr('Bicarbonate (HCO3)'),
      value: wa.bicarbonate.value,
      unit: wa.bicarbonate.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.tds = new NumberProperty({
      label: Localization.tr('TDS'),
      value: wa.tds.value,
      unit: wa.tds.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.tss = new NumberProperty({
      label: Localization.tr('TSS'),
      value: wa.tss.value,
      unit: wa.tss.unit,
      min: 0,
      max: 1000,
      step: 0.01
    })

    this.ammonia = new NumberProperty({
      label: Localization.tr('Ammonia (NH3)'),
      value: wa.ammonia.value,
      unit: wa.ammonia.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.iron = new NumberProperty({
      label: Localization.tr('Iron (Fe)'),
      value: wa.iron.value,
      unit: wa.iron.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.chlorine = new NumberProperty({
      label: Localization.tr('Residual chlorine (Cl2)'),
      value: wa.chlorine.value,
      unit: wa.chlorine.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })

    this.hydrogenSulfide = new NumberProperty({
      label: Localization.tr('Hydrogen Sulfide (H2S)'),
      value: wa.hydrogenSulfide.value,
      unit: wa.hydrogenSulfide.unit,
      min: 0,
      max: 1000,
      step: 0.001
    })
  }

  export() {
    return {
      date: this.dateTime.formattedValue,
      dissolvedOxygen: this.dissolvedOxygen.formattedValue,
      temperature: this.temperature.formattedValue,
      ph: this.ph.formattedValue,
      oxygenSaturation: this.oxygenSaturation.formattedValue,
      salinity: this.salinity.formattedValue,
      turbidity: this.waterClarity.formattedValue,
      waterGravity: this.waterGravity.formattedValue,
      ammonium: this.ammonium.formattedValue,
      specie: this.application.formattedValue,
      totalHardness: this.hardness.formattedValue,
      avgHardness: this.avgHardness.formattedValue,
      phosphates: this.phosphates.formattedValue,
      nitrites: this.nitrites.formattedValue,
      nitrates: this.nitrates.formattedValue,
      magnesium: this.magnesium.formattedValue,
      potassium: this.potassium.formattedValue,
      calcium: this.calcium.formattedValue,
      alkalinity: this.alkalinity.formattedValue,
      carbonate: this.carbonate.formattedValue,
      bicarbonate: this.bicarbonate.formattedValue,
      tds: this.tds.formattedValue,
      tss: this.tss.formattedValue,
      ammonia: this.ammonia.formattedValue,
      iron: this.iron.formattedValue,
      chlorine: this.chlorine.formattedValue,
      hydrogenSulfide: this.hydrogenSulfide.formattedValue
    }
  }
}
