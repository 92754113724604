import { Property } from './property.js'
export class CollectionProperty extends Property {
  constructor({ value, label, formatValue, checkEmpty } = {}) {
    super({
      value: value || [],
      label,
      formatValue: formatValue || (v => v)
    })

    if (checkEmpty) {
      this.hasValue = !checkEmpty()
    } else {
      this.hasValue = Boolean(value && value.length)
    }
  }
}
