import { AuthorizationGetter } from '../../node_modules/@xpertsea/scuba/dist/esm/index.js'

export class GlobalMediatorAuthorizationGetter extends AuthorizationGetter {
  constructor(globalMediator) {
    super()
    this.globalMediator = globalMediator
  }

  getAuthorization() {
    if (!this.globalMediator || !this.globalMediator.currentState || !this.globalMediator.currentState.authorization) {
      return {}
    }

    return {
      accessToken: this.globalMediator.currentState.authorization.accessToken,
      expirationDate: this.globalMediator.currentState.authorization.expirationDate,
      userId: this.globalMediator.currentState.authorization.userId
    }
  }

  getCredentials() {
    return null
  }

  onRenewAuthorization(renewedAuth) {
    // do nothing
  }
}
