import '../../../node_modules/@polymer/paper-button/paper-button.js'

import { AnalyticsMixin } from '../analytics/analytics-mixin.js'

const PaperButtonElement = customElements.get('paper-button')
export class SeaButton extends AnalyticsMixin(PaperButtonElement) {
  constructor() {
    super()

    // Workaround for Polymer 3.2.0 - see https://github.com/PolymerElements/iron-a11y-keys-behavior/issues/93
    this._keyBindings = this._keyBindings || {}
    this.addEventListener('tap', this._onTap)
  }

  _onTap() {
    this._sendEvent('button-clicked')
  }
}
customElements.define('sea-button', SeaButton)
