import {
  BooleanProperty,
  CollectionProperty,
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  ObjectProperty,
  TextProperty
} from '../models/properties.js'

import { AnimalAmountPerWeightCostsModel } from './animal-amount-per-weight-costs-model.js'
import { ApplicationSettingsMixin } from '../../components/mixins/application-settings-mixin.js'
import { FeedingRateModel } from '../populations/feeding-rate-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'
import { Strings } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'

export const APPLICATION_STATUSES = {
  released: Localization.tr('Released'),
  beta: Localization.tr('Beta'),
  dev: Localization.tr('Dev'),
  deleted: Localization.tr('Deleted')
}

export const APPLICATION_VOLUME_TO_LITER = 1000

export class ApplicationModel extends ApplicationSettingsMixin(Model) {
  constructor(a = {}) {
    super(a)
    this.__dictionary = Localization.buildDictionary(a.translations)
    this.name = new TextProperty({
      label: Localization.tr('Name'),
      value: a.name,
      formatValue: value => Strings.capitalize(this.__dictionary[value] || value)
    })

    this.latinName = new TextProperty({
      label: Localization.tr('Latin name'),
      value: a.latinName,
      formatValue: value => Strings.capitalize(value)
    })

    this.unit = new TextProperty({
      label: Localization.tr('Unit'),
      value: a.unit,
      formatValue: value => ApplicationModel.getFormattedUnit(this.__dictionary, value)
    })

    this.workflows = new CollectionProperty({
      value: a.workflows
    })

    this.stageRange = new CollectionProperty({
      value: a.stageRange
    })

    this.stageAtStockingRange = new CollectionProperty({
      value: a.stageAtStockingRange
    })

    this.supportsConcentration = new BooleanProperty({
      value: a.supportsConcentration
    })

    this.supportsLength = new BooleanProperty({
      value: a.supportsLength
    })

    this.supportsWeight = new BooleanProperty({
      value: a.supportsWeight
    })

    this.supportsFeeding = new BooleanProperty({
      value: a.supportsFeeding
    })

    this.supportsFeedCheck = new BooleanProperty({
      value: a.supportsFeedCheck
    })

    this.supportsAsc = new BooleanProperty({
      value: a.supportsAsc
    })

    this.supportsHarvest = new BooleanProperty({
      value: a.supportsHarvest
    })

    this.supportsFeedRx = new BooleanProperty({
      value: a.supportsFeedRx
    })

    this.supportsCosts = new BooleanProperty({
      value: a.supportsCosts
    })

    this.image = new ImageProperty({
      value: a.image
    })

    this.imageLink = new ImageProperty({
      value: a.imageLink
    })

    this.lastUpdated = new DateTimeProperty({
      label: Localization.tr('Last updated'),
      value: a.lastUpdated
    })

    this.createdAt = new DateTimeProperty({
      label: Localization.tr('Created'),
      value: a.createdAt
    })

    this.status = new TextProperty({
      label: Localization.tr('Status'),
      value: a.status,
      formatValue: value => APPLICATION_STATUSES[value] || Strings.capitalize(value)
    })

    this.version = new TextProperty({
      label: Localization.tr('Version'),
      value: a.version
    })

    this.rangeLabel = new TextProperty({
      label: Localization.tr('Operating range'),
      value: a.rangeLabel
    })

    this.operatingVolume = new NumberProperty({
      label: Localization.tr('Operating volume'),
      value: a.smallVolume ? a.smallVolume.volume : null,
      unit: 'L',
      formatValue: value => (value ? value / APPLICATION_VOLUME_TO_LITER : null)
    })

    this.smallVolume = new ObjectProperty({
      label: Localization.tr('Small volume'),
      value: a.smallVolume
    })

    this.largeVolume = new ObjectProperty({
      label: Localization.tr('Large volume'),
      value: a.largeVolume
    })

    this.workflowDefinitions = new CollectionProperty({
      label: Localization.tr('Workflow definitions'),
      value: a.workflowDefinitions
    })

    this.harvestBreakdown = new CollectionProperty({
      label: Localization.tr('Harvest breakdown'),
      value: a.harvestBreakdown
    })
  }

  hasWorkflow(workflow = '') {
    return this.workflows.value.includes(workflow)
  }

  serialize() {
    const a = super.serialize()

    return Object.assign({}, a, this.serializeUpdatableSettings())
  }

  static fromSerialized(a) {
    if (!a) {
      return null
    }

    if (a.animalAmountPerWeightCosts) {
      a.animalAmountPerWeightCosts = a.animalAmountPerWeightCosts.map(apw => new AnimalAmountPerWeightCostsModel(apw))
    }

    if (a.feedingRates) {
      a.feedingRates = a.feedingRates.map(fr => new FeedingRateModel(fr))
    }

    return new ApplicationModel(a)
  }

  static getFormattedUnit(dictionary, value) {
    const dictValue = dictionary[Strings.capitalize(value)]
    if (dictValue) {
      return dictValue.toLowerCase()
    }

    return value
  }

  getStatusBadge() {
    return this.status.formattedValue === 'Released' ? 'success' : ''
  }
}
