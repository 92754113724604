import { DateTime } from '../../../node_modules/luxon/src/luxon.js'
import { Functional } from '../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022'

export class Timezones {
  constructor() {
    this._memoizedTimezones = null
  }

  get list() {
    if (!this._memoizedTimezones) {
      this._memoizedTimezones = this._computeTimezones()
    }

    return this._memoizedTimezones
  }

  _computeTimezones() {
    // workaround: Using moment because Luxon does not have a way to list all timezone
    // https://github.com/moment/luxon/issues/353
    return moment.tz
      .names()
      .filter(tz => DateTime.local().setZone(tz).isValid)
      .map(code => {
        const timezone = moment.tz(code)
        const [area, region, subregion] = code.split('/').map(part => part.split('_').join(' '))
        return {
          code,
          area,
          region,
          subregion,
          utc: `UTC${timezone.format('Z')}`,
          offset: timezone.utcOffset()
        }
      })
      .filter(t => !t.code.includes('GMT'))
      .sort(Functional.sort({ by: 'offset' }))
      .map(timezone => {
        const { code, area, region, subregion, utc } = timezone
        let label = `(${utc}) `

        if (subregion) {
          label += `${region}, ${subregion}`
        } else if (region) {
          label += region
        } else {
          label += area
        }

        return {
          code,
          label
        }
      })
  }
}
