import { ApiErrorHandler } from './api-error-handler.js'
import { ApplicationModelConverter } from './applications/application-model-converter.js'
import { Configuration } from '../../configuration.js'
import { ContainerModelConverter } from './containers/container-model-converter.js'
import { CustomerModelConverter } from './customers/customer-model-converter.js'
import { GlobalMediatorAuthorizationGetter } from './authorization-getter.js'
import { HarvestModelConverter } from './harvests/harvest-model-converter.js'
import { MealModelConverter } from './meals/meal-model-converter.js'
import { MonitoringModelConverter } from './monitorings/monitoring-model-converter.js'
import { OrganizationModelConverter } from './organizations/organization-model-converter.js'
import { UserModelConverter } from './users/user-model-converter.js'
import { WaterAnalysisModelConverter } from './water-analysis/water-analysis-model-converter.js'
import { WorkflowDefinitionModelConverter } from './workflow-definitions/workflow-definition-model-converter.js'
import { api } from '../../node_modules/@xpertsea/scuba/dist/esm/index.js'

// FIXME - Class deprecated. Kill it when everything is migrated to Scuba
export class Repository {
  constructor({ globalMediator = window.GlobalMediator } = {}) {
    this.globalMediator = globalMediator
  }

  get authorization() {
    return this.globalMediator.currentState.authorization
  }

  get organization() {
    return this.globalMediator.currentState.organization
  }
}

const baseConfig = {
  apiConfig: {
    hosts: Configuration.hosts
  },
  authorization: new GlobalMediatorAuthorizationGetter(window.GlobalMediator),
  apiErrorHandler: new ApiErrorHandler()
}

api
  .init(baseConfig)
  .initApplications({
    ...baseConfig,
    modelConverter: new ApplicationModelConverter()
  })
  .initContainers({
    ...baseConfig,
    modelConverter: new ContainerModelConverter()
  })
  .initCustomers({
    ...baseConfig,
    modelConverter: new CustomerModelConverter()
  })
  .initHarvests({
    ...baseConfig,
    modelConverter: new HarvestModelConverter()
  })
  .initUsers({
    ...baseConfig,
    modelConverter: new UserModelConverter()
  })
  .initMeals({
    ...baseConfig,
    modelConverter: new MealModelConverter()
  })
  .initMonitorings({
    ...baseConfig,
    modelConverter: new MonitoringModelConverter()
  })
  .initOrganizations({
    ...baseConfig,
    modelConverter: new OrganizationModelConverter()
  })
  .initWaterAnalysis({
    ...baseConfig,
    modelConverter: new WaterAnalysisModelConverter()
  })
  .initWorkflowDefinition({
    ...baseConfig,
    modelConverter: new WorkflowDefinitionModelConverter()
  })

export const scubaApi = api
