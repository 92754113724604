import {
  ApiErrorHandler as ScubaApiErrorHandler,
  Unauthorized
} from '../../node_modules/@xpertsea/scuba/dist/esm/index.js'

import { Platform } from '../libs/platform.js'

export class ApiErrorHandler extends ScubaApiErrorHandler {
  handle(httpError) {
    if (httpError instanceof Unauthorized) {
      Platform.dispatchEvent(new CustomEvent('http-unauthorized', { bubbles: true }))
    }

    return 'THROW'
  }
}
