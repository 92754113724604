import { BooleanProperty } from '../../data/models/properties.js'
import { Localization } from '../../libs/localization/localization.js'
import { dedupingMixin } from '../../../node_modules/@polymer/polymer/lib/utils/mixin.js'

export const ProcessingStatusMixin = dedupingMixin(Base => {
  /**
   * @polymer
   * @mixinClass
   */
  return class ProcessingStatusMixinClass extends Base {
    constructor(w = {}) {
      super(w)
      this.processed = new BooleanProperty({
        label: Localization.tr('Processed'),
        value: w.processed !== false,
        formatValue:
          w.processed === false
            ? () => {
                return Localization.tr('Processing results...')
              }
            : null
      })

      this.hasWarnings = new BooleanProperty({
        label: Localization.tr('Has warnings'),
        value: w.hasWarnings,
        formatValue: w.hasWarnings
          ? () => {
              return Localization.tr('Some samples contain errors.')
            }
          : null
      })
    }

    _getProcessingOrWarningsFormatValue() {
      return () => {
        if (!this.processed.value) {
          return '...'
        }

        return '-'
      }
    }
  }
})
