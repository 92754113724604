import { HarvestBreakdownModel } from '../harvest-breakdown-model.js'
import { Strings } from '../../../libs/strings.js'

export class DeepwaterHarvestBreakdownMapper {
  static convert(amountPerWeightHarvested) {
    const models = []
    const range = amountPerWeightHarvested.length

    for (let i = 0; i < range; i++) {
      const animalAmountPerWeight = amountPerWeightHarvested[i]
      const nextAnimalAmountPerWeight = amountPerWeightHarvested[i + 1]
      const { amountPerWeight, totalHarvested } = animalAmountPerWeight

      const label = Strings.rangeLabel(
        i,
        animalAmountPerWeight.amountPerWeight,
        nextAnimalAmountPerWeight ? nextAnimalAmountPerWeight.amountPerWeight - 1 : null
      )

      models.push(
        new HarvestBreakdownModel({
          amountPerWeight,
          totalHarvested,
          label
        })
      )
    }

    return models
  }
}
