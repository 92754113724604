import '../../node_modules/@polymer/iron-location/iron-location.js'
import '../components/app-notification.js'
import '../components/page-router.js'
import '../styles/icons.js'
import '../components/segment-analytics.js'

import { SeaElement, html } from '../components/element/sea-element.js'

import { Analytics } from '../libs/analytics.js'
import { Feature } from '../libs/feature.js'
import { GlobalMediatorMixin } from '../components/global-mediator-mixin.js'
import { Localization } from '../libs/localization/localization.js'
import { Log } from '../libs/log.js'
import { Persistence } from '../data/persistence.js'
import { Platform } from '../libs/platform.js'
import { ServiceWorkerHandlerMixin } from '../components/service-worker-handler-mixin.js'
import { SupportedLanguages } from '../libs/localization/supported-languages.js'
import { scubaApi } from '../data/repository.js'

export class XpertseaApp extends GlobalMediatorMixin(ServiceWorkerHandlerMixin(SeaElement)) {
  static get template() {
    return html`
      <style>
        :host(:not([is-splashing])) slot {
          display: none;
        }

        :host([is-splashing]) page-router {
          display: none;
        }
      </style>

      <slot></slot>
      <iron-location path="{{path}}" on-path-changed="_onPathChanged"></iron-location>
      <segment-analytics></segment-analytics>
      <page-router route="[[route]]" on-page-selected="_onPageSelected">
        <xpertsea-page route-pattern="/xpertsea"></xpertsea-page>
        <admin-page route-pattern="/admin"></admin-page>
        <organization-page route-pattern="/organization"></organization-page>
        <activation-page route-pattern="/activation/:token" track></activation-page>
        <forgot-page route-pattern="/forgot" track></forgot-page>
        <reset-page route-pattern="/reset/:token" track></reset-page>
        <signin-page route-pattern="/signin" track></signin-page>
        <zendesk-sso-page route-pattern="/zendesk-sso" track></zendesk-sso-page>
      </page-router>
      <app-notification notification="[[_notification]]"></app-notification>
    `
  }

  static get properties() {
    return {
      path: String,
      route: {
        type: Object,
        computed: '_computeRoute(path)'
      },
      isSplashing: {
        type: Boolean,
        reflectToAttribute: true
      },
      _defaultPath: {
        type: String,
        computed: '_computeDefaultPath(state.authorization, state.user, state.organization)'
      },
      _notification: Object,
      _text: {
        type: Object,
        readonly: true,
        value: {
          loadElementErrorMessage: Localization.tr('Something went wrong while loading the page')
        }
      }
    }
  }

  static get observers() {
    return [
      '_routeChanged(route, state.authorization)',
      '_observeForLogContext(state.user, state.organization, state.customer)',
      '_observeForLocalizationLanguage(state.user)',
      '_observeForLocalizationTimezone(state.user, state.organization)'
    ]
  }

  constructor(args = {}) {
    super(args)
    this.addEventListener('sea-navigation', this._onAppNavigation)
    this.addEventListener('app-notification', this._onAppNotification)
    this.addEventListener('app-close-notification', this._onAppCloseNotification)
    this.addEventListener('app-not-found', this._onAppNotFound)
    this.addEventListener('app-stop-splashing', this._onAppStopSplashing)
    this.addEventListener('analytics-page-load-started', this._onAnalyticsPageLoadStarted)
    this.addEventListener('analytics-page-load-finished', this._onAnalyticsPageLoadFinished)
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.state && this.state.authorization && this.state.user) {
      this._refreshCurrentUser({ userId: this.state.user.id.value })
    }

    this.registerServiceWorker()
  }

  _computeRoute(path) {
    return { path }
  }

  _computeDefaultPath(authorization, user, organization) {
    if (!authorization || !user) {
      return '/react/signin'
    }

    if (user.isTransactionsAdmin()) {
      return '/react/transactions'
    }

    if (user.isXpertsea.value) {
      return '/xpertsea/customers'
    }

    return organization ? `/organization/${organization.id.value}/activity` : '/organization'
  }

  _routeChanged(route, authorization) {
    this.updateServiceWorker()
    if (!Persistence.isUpToDate()) {
      Persistence.clearHard()
      return Platform.refresh()
    }

    if (authorization && !Feature.isValidAuthorization(authorization)) {
      this._unauthorize()
    }
  }

  _observeForLogContext(user, organization, customer) {
    Analytics.identify(user)
    Log.updateContext({ user, organization, customer })
  }

  _observeForLocalizationLanguage(user) {
    if (
      !user ||
      !user.language.value ||
      user.language.value === Localization.currentLanguage ||
      Localization.hasAlreadyAskedToUpdateLanguage()
    ) {
      return
    }

    const userLanguage = SupportedLanguages.fromCode(user.language.value)
    this._notification = {
      message: userLanguage.messages.reloadInYourLanguage,
      choices: [
        {
          text: userLanguage.messages.yes,
          action: () => Localization.changeLanguage(user.language.value)
        },
        {
          text: userLanguage.messages.no,
          action: () => Localization.dontAskToUpdateLanguageAgain()
        }
      ]
    }
  }

  _observeForLocalizationTimezone(user, organization) {
    if (!user) {
      return
    }

    const effectiveTimezone = user.timezone.value || (organization ? organization.timezone.value : null)
    if (!effectiveTimezone || effectiveTimezone === Localization.getCurrentTimezone()) {
      return
    }

    try {
      Localization.changeTimezone(effectiveTimezone)
    } catch (error) {
      Log.error(error)
    }
  }

  _onAppNavigation(event) {
    const { path, external = false, redirecting = false } = event.detail
    if (path) {
      if (external) {
        window.location.assign(path)
      } else if (path.startsWith('/react')) {
        Platform.redirectTo({ path: window.location.origin + path })
      } else {
        return redirecting ? Platform.redirectTo({ path }) : Platform.navigateTo({ path })
      }
    }
  }

  _onAppNotification(event) {
    const notification = event.detail
    this.set('_notification', notification)
  }

  _onAppCloseNotification(event) {
    this.set('_notification', null)
  }

  _onAppNotFound(event) {
    Platform.redirectTo({ path: this._defaultPath })
  }

  _onAppStopSplashing(event) {
    this.isSplashing = false
  }

  _onAnalyticsPageLoadStarted(event) {
    const { pageName } = event.detail
    Analytics.track('page-load-started', { pageName })
  }

  _onAnalyticsPageLoadFinished(event) {
    const { pageName } = event.detail
    Analytics.track('page-load-finished', { pageName })
    Analytics.page(pageName)
  }

  _onPathChanged(event) {
    Analytics.track('url-changed')
  }

  async _onPageSelected(event) {
    const { page, properties } = event.detail
    if (!page) {
      return
    }

    if (page.hasAttribute('track')) {
      this.raise('analytics-page-load-started', { pageName: page.tagName.toLowerCase() })
    }

    try {
      const routePattern = page.getAttribute('route-pattern')
      if (routePattern === '/not-found') {
        return Platform.redirectTo({ path: this._defaultPath })
      }

      await Platform.lazyLoadElement({
        element: page,
        load: ({ elementTagName }) => this._loadElement({ elementTagName }),
        afterLoad: () => page.setProperties(properties)
      })
    } catch (error) {
      Log.error(error)
      this.raise('app-notification', { message: this._text.loadElementErrorMessage })
    }
  }

  async _refreshCurrentUser({ userId }) {
    try {
      const user = await scubaApi.users.findById(userId)
      this.raise('app-update-user', { user })
    } catch (error) {
      Log.error(error)
    }
  }

  _loadElement({ elementTagName }) {
    switch (elementTagName) {
      case 'xpertsea-page': {
        return import('./xpertsea/xpertsea-page.js')
      }

      case 'admin-page': {
        return import('./admin/admin-page.js')
      }

      case 'activation-page': {
        return import('./activation-page.js')
      }

      case 'organization-page': {
        return import('./organization/organization-page.js')
      }

      case 'forgot-page': {
        return import('./forgot-page.js')
      }

      case 'reset-page': {
        return import('./reset-page.js')
      }

      case 'signin-page': {
        return import('./signin-page.js')
      }

      case 'zendesk-sso-page': {
        return import('./zendesk-sso-page.js')
      }

      case 'report-bundle': {
        // This is never called, it's just for the bundler
        return import('../reports/report-router.js')
      }

      default: {
        throw new Error(`Can't load unknown page: ${elementTagName}`)
      }
    }
  }
}

customElements.define('xpertsea-app', XpertseaApp)
