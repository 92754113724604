import { AppVersion } from '../../version.js'
import { Log } from '../libs/log.js'
import { Persistence } from '../data/persistence.js'
import { Platform } from '../libs/platform.js'
import { SeaElementMixin } from './element/sea-element.js'
import { dedupingMixin } from '../../node_modules/@polymer/polymer/lib/utils/mixin.js'

export const ServiceWorkerHandlerMixin = dedupingMixin(
  Base =>
    class ServiceWorkerHandlerMixinClass extends SeaElementMixin(Base) {
      static get properties() {
        return {
          serviceWorkerRegistration: Object
        }
      }

      async registerServiceWorker() {
        if (!Platform.isServiceWorkerSupported) {
          return
        }

        try {
          this.serviceWorkerRegistration = await Platform.registerServiceWorker({
            onUpdateFound: ({ newServiceWorker }) => this.__onServiceWorkerUpdateFound({ newServiceWorker })
          })
        } catch (error) {
          Log.error(`An error occured while registering the service worker: ${error}`)
        }
      }

      updateServiceWorker() {
        if (this.serviceWorkerRegistration) {
          this.serviceWorkerRegistration.update()
        }
      }

      __onServiceWorkerUpdateFound({ newServiceWorker }) {
        newServiceWorker.addEventListener('statechange', () =>
          this.__onNewServiceWorkerStateChange({ state: newServiceWorker.state })
        )
      }

      async __onNewServiceWorkerStateChange({ state }) {
        if (state === 'activated') {
          Persistence.resetWhatsNew(AppVersion)

          await Platform.clearCaches({ exceptions: ['sw-precache'] })
          Platform.refresh()
        }
      }
    }
)
