import { html } from '../components/element/sea-element.js'
import { register } from './style-module.js'

register({
  name: 'shared',
  template: html`
    <style>
      p {
        line-height: 1.6;
        color: var(--sea-dark-secondary-text-color);
        font-size: 1rem;
      }

      .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      dom-if,
      :host([hidden]),
      [hidden] {
        display: none !important;
      }

      iron-icon {
        color: var(--sea-icon-color);
      }

      iron-icon.small {
        width: 0.9rem;
      }

      :host {
        --paper-button: {
          margin: 0;
        }

        --paper-input-container-focus-color: var(--sea-light-accent-color);

        --paper-input-container-underline: {
          border-color: var(--sea-dark-divider-color);
        }
      }

      paper-menu-button {
        --paper-menu-button: {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      paper-progress {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        --paper-progress-height: 0.25rem;
        --paper-progress-active-color: var(--sea-light-accent-color);
        --paper-progress-container-color: transparent;
        z-index: 1;
      }

      paper-textarea[readonly] {
        --paper-input-container-focus-color: var(--sea-dark-secondary-text-color);

        --paper-input-container-underline: {
          display: none;
        }

        --paper-input-container-underline-focus: {
          display: none;
        }
      }

      paper-input[type='number'] {
        --paper-input-container-input-webkit-spinner: {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      paper-input[type='date'] {
        --paper-input-container-input-webkit-spinner: {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      paper-tooltip {
        --paper-tooltip-delay-in: 300ms;
        --paper-tooltip-delay-out: 200ms;
        --paper-tooltip-duration-in: 150ms;
        --paper-tooltip-duration-out: 150ms;

        --paper-tooltip: {
          font-size: 0.8rem;
          font-weight: 700;
        }
      }

      paper-item {
        line-height: 1.4;
      }

      .sea-label {
        color: var(--sea-dark-secondary-text-color);
        font-size: 0.875rem;
        line-height: 1.2;
      }

      @media screen and (min-width: 769px) {
        app-header {
          z-index: 100;
        }

        .main {
          z-index: 50;
        }

        paper-tooltip.no-delay {
          --paper-toolip-delay-in: 0;
        }
      }

      @media screen and (max-width: 1024px) {
        paper-tooltip:not([mobile-enabled]) {
          display: none;
        }
      }

      sea-link[disabled] {
        pointer-events: none;
        cursor: default;
      }

      sea-icon-button {
        color: var(--sea-icon-color);
      }

      sea-icon-button:hover {
        color: var(--sea-icon-color-active);
        background-color: var(--sea-icon-background-color-active);
        border-radius: 50%;
      }
    </style>
  `
})
