import { ContainerModel } from './container-model.js'
import { Log } from '../../libs/log.js'
import { ModelConverter } from '../../../node_modules/@xpertsea/scuba/dist/esm/index.js'
import { StateMapper } from '../models/state-mapper.js'

export class ContainerModelConverter extends ModelConverter {
  convert(c) {
    let organization = null
    try {
      organization = StateMapper.findOrganization({
        id: c.organizationId
      })
    } catch (Exception) {
      Log.info(`Organization ${c.organizationId} not found in State`)
    }

    return new ContainerModel(
      Object.assign({}, c, {
        organization
      })
    )
  }
}
