import { Dates } from '../../../../node_modules/@xpertsea/aquarius/dist/esm/index.js'
import { Localization } from '../../../libs/localization/localization.js'
import { Property } from './property.js'

export class TimeProperty extends Property {
  constructor({ value, label, formattedDefault = '' } = {}) {
    super({
      value: Dates.from({
        source: value
      }),
      label,
      formatValue: v =>
        Dates.format({
          date: v,
          as: 'time',
          tz: Localization.getCurrentTimezone()
        })
    })

    if (!this.formattedValue) {
      this.formattedValue = formattedDefault
    }
  }
}
