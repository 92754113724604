import {
  CollectionProperty,
  CurrencyProperty,
  DateTimeProperty,
  NumberProperty,
  ObjectProperty,
  TextProperty,
  TimeProperty
} from '../models/properties.js'

import { ApplicationModel } from '../applications/application-model.js'
import { Localization } from '../../libs/localization/localization.js'
import { Model } from '../models/model.js'

export class HarvestModel extends Model {
  constructor(m = {}) {
    super(m)
    this.application = new ObjectProperty({
      label: Localization.tr('Species'),
      value: m.application,
      type: ApplicationModel,
      formatValue: value => value.name.formattedValue
    })

    this.applicationUnit = new TextProperty({
      label: Localization.tr('Unit'),
      value: this.application.value ? this.application.value.unit.value : null,
      formatValue: () =>
        this.application.value ? this.application.value.unit.formattedValue : Localization.tr('animals')
    })

    this.populationName = new TextProperty({
      label: Localization.tr('Population Name'),
      value: m.populationName
    })

    this.populationId = new TextProperty({
      label: Localization.tr('Population ID'),
      value: m.populationId
    })

    this.dateTime = new DateTimeProperty({
      label: Localization.tr('Date & time'),
      value: m.dateTime
    })

    this.time = new TimeProperty({
      label: Localization.tr('Time'),
      value: m.dateTime
    })

    this.biomassHarvested = new NumberProperty({
      label: Localization.tr('Biomass harvested'),
      value: m.biomassHarvested,
      unit: Localization.tr('kg'),
      step: 0.001,
      min: 1
    })

    this.customer = new TextProperty({
      label: Localization.tr('Customer'),
      value: m.customer
    })

    this.comment = new TextProperty({
      label: Localization.tr('Comment'),
      value: m.comment
    })

    this.currency = new TextProperty({
      label: Localization.tr('Currency'),
      value: m.currency
    })

    this.totalSales = new CurrencyProperty({
      label: Localization.tr('Total sales'),
      value: m.totalSales,
      min: 1,
      currency: m.currency
    })

    this.piecesHarvested = new NumberProperty({
      label: Localization.tr('Pieces harvested'),
      value: m.piecesHarvested,
      min: 0,
      unit: Localization.tr('pieces')
    })

    this.avgCountPerWeight = new NumberProperty({
      label: Localization.tr('Avg count/kg'),
      value: m.avgCountPerWeight,
      min: 0,
      step: 0.01,
      unit: Localization.tr('/kg')
    })

    this.biomassBroken = new NumberProperty({
      label: Localization.tr('Biomass broken'),
      value: m.biomassBroken,
      min: 0,
      step: 0.01,
      unit: Localization.tr('kg')
    })

    this.transportDocReference = new TextProperty({
      label: Localization.tr('Transport document reference'),
      value: m.transportDocReference
    })

    this.batchNumber = new TextProperty({
      label: Localization.tr('Batch number'),
      value: m.batchNumber
    })

    this.breakdown = new CollectionProperty({
      label: Localization.tr('Harvest breakdown'),
      value: m.breakdown,
      checkEmpty: () => this._hasNoBreakdownValue(m.breakdown),
      formatValue: v => {
        if (!v || !v.length) {
          return null
        }

        const h = v.reduce((prev, curr) => (prev.totalHarvested.value > curr.totalHarvested.value ? prev : curr))
        if (!h.totalHarvested.value) {
          return null
        }

        return h.amountPerWeight.formattedValueWithUnit
      }
    })
  }

  export() {
    let fields = {
      specie: this.application.formattedValue,
      populationId: this.populationName.formattedValue,
      date: this.dateTime.formattedValue,
      biomassHarvested: this.biomassHarvested.formattedValue,
      customer: this.customer.formattedValue,
      comment: this.comment.formattedValue,
      currency: this.currency.formattedValue,
      totalSales: this.totalSales.formattedValue,
      piecesHarvested: this.piecesHarvested.formattedValue,
      avgCountPerWeight: this.avgCountPerWeight.formattedValue,
      biomassBroken: this.biomassBroken.formattedValue,
      breakdown: this.breakdown.formattedValue
    }

    const includeAscOnly = this.application.value && this.application.value.supportsAsc.value
    if (includeAscOnly) {
      fields = Object.assign({}, fields, {
        transportDocReference: this.transportDocReference.formattedValue,
        batchNumber: this.batchNumber.formattedValue
      })
    }

    return fields
  }

  _hasNoBreakdownValue(breakdown) {
    if (!breakdown || !breakdown.length) {
      return false
    }

    return !breakdown.some(b => b.totalHarvested.value != null)
  }
}
